export function checkFields(fieldsRequire, userInfos, password, setErrorMessage, nextFunction) {
  try {
    // Checking if all required field aren't empty
    fieldsRequire.forEach((field) => {
      if (userInfos[field] === '')
        throw new Error('Preencha todos os campos obrigatórios.');
    });

    // Checking if password is in correct format
    checkPassword(userInfos.password);

    // Checking if the passwords typed have the same value
    if (password !== userInfos.password)
      throw new Error('Repita a senha corretamente.');

    setErrorMessage('');
    nextFunction(4);
  } catch (error) {
    setErrorMessage(error.message);
  }
}

export function checkAgencyFields(fieldsRequire, agencyInfos, userInfos, nextFunction, setErrorMessage, next) {
  try {
    // Checking if all required field aren't empty
    fieldsRequire.forEach((field) => {
      if (agencyInfos[field] === '')
        throw new Error('Preencha todos os campos obrigatórios.');
    });

    setErrorMessage('');
    const res = nextFunction(userInfos, { ...agencyInfos, country: userInfos.country, state: userInfos.state, city: userInfos.city }, next);

    if (!res) {
      setErrorMessage("Ocorreu um erro ao criar usuário e empresa.");
      window.location.href = "/cae-login";
    }
  } catch (error) {
    setErrorMessage(error.message);
  }
}

export function checkFieldsContact(fieldsRequire, userInfos, setErrorMessage, next) {
  try {
    // Checking if all required field aren't empty
    fieldsRequire.forEach((field) => {
      if (userInfos[field] === '')
        throw new Error('Preencha todos os campos obrigatórios.');
    });

    setErrorMessage('');
    next(userInfos);
  } catch (error) {
    setErrorMessage(error.message);
  }
}

function checkPassword(password) {
  if (password.length < 6 || password.length > 18)
    throw new Error('A senha deve ter ao menos 6 caracteres, e no máximo 18 caracteres.');

  if (!(/\d/.test(password)))
    throw new Error('A senha deve ter ao menos 1 caractere númerico.');

  if (!(/[A-Z]/.test(password)))
    throw new Error('A senha deve ter ao menos 1 letra maiúscula.');

  if (!(/[a-z]/.test(password)))
    throw new Error('A senha deve ter ao menos 1 letra minúscula.');
}