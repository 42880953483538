import React, { useState } from "react";

import UploadIcon from '../../assets/imgs/upload_icon.svg';
import DeleteIcon from '../../assets/imgs/delete_icon_image.svg';

export function ImageField({ title, fieldForm, setValue, value, disabled }) {
  const [images, setImages] = useState(value[fieldForm] || []);
  const [messageError, setMessageError] = useState('');

  function handleAddImage(e) {
    const keys = Object.keys(e.target.files);
    const newImages = [...images];

    if (newImages.length + keys.length > 3) {
      setMessageError('Limite de 3 Fotos por experiência.');
      return;
    }

    for (let key = 0; key < keys.length; key++) {
      const file = e.target.files[keys[key]];

      if (file.size / 1024 <= 1200) {
        newImages.push({
          lastModifiedDate: handleTextDate(new Date()),
          name: file.name,
          size: file.size,
          src: URL.createObjectURL(file),
          file: file,
        }); 
      } else {
        setMessageError('Tamanho de uma das fotos excede 1 MB (Megabytes)');
        return;
      }
    }

    setMessageError('');
    setImages(newImages);
    setValue({ ...value, [fieldForm]: newImages });
  }

  function handleTextDate(date) {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  function handleTextSize(size) {
    return (size / 1000).toFixed(1).toString() + ' Kb';
  }

  function deleteImage(indexImage) {
    const newImages = images.filter((_, index) => index !== indexImage);
    
    setImages(newImages);
    setValue({ ...value, [fieldForm]: newImages });
  }

  return (
    <div className="field-image-edit">
      <span className="title-image-edit">{title}</span>
      <span className="subtitle-image-edit">Lista de fotos. Tamanho máximo por arquivo 1MB (MegaByte)</span>
      { messageError !== '' ? <span className="error-message-form-cae error-message-login">{messageError}</span> : null }
      <label className="div-upload-image">
        <input type="file" accept="image/png, image/jpg, image/jpeg" onChange={(e) => handleAddImage(e)} multiple="multiple" disabled={disabled} />
        <img src={UploadIcon} />
        <span>Clique ou arraste para carregar uma imagem.</span>
      </label>
      <div className="div-align-images-field">
        {
          images.map((img, index) => {
            return (
              <div className="div-image-edit" key={title + img.name + index}>
                <div className="preview-image">
                  <div className="div-limit-image">
                    <img src={img.src} />
                  </div>
                  <span>{img.name}</span>
                </div>
                <div className="info-image">
                  <span>{img.lastModifiedDate}</span>
                  <span className="size-file">{handleTextSize(img.size)}</span>
                  <img src={DeleteIcon} onClick={() => (!disabled ? deleteImage(index) : null)} />
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}