import React from "react";

import '../assets/css/popupNewUser.css';

import CompleteIcon from '../assets/imgs/complete_signin_icon.svg';


export function PopupNewUser({ setShowPopup }) {
  return (
    <div className="PopupNewUser">
      <div className="inner-popup-user">
        <div className="div-aling-img-popup-newuser">
          <img src={CompleteIcon} />
        </div>
        <div className="div-align-info-popup-newuser">
          <span>Cadastro concluído!</span>
          <span>Agora você tem acesso ao Portal CAE e poderá cadastrar sua experiências turísticas.</span>
        </div>
        <div className="div-align-button-newuser">
          <button onClick={() => { localStorage.setItem('isNewUser', 'false'); setShowPopup(false); }}>Continuar</button>
        </div>
      </div>
    </div>
  );
}