exports.convertDate = (timeStamp) => {
  const date = new Date(timeStamp.seconds * 1000);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return day + '/' + month + '/' + year;
};

exports.convertTime = (timeStamp) => {
  const date = new Date(timeStamp.seconds * 1000);

  let hour = date.getHours();
  let minutes = date.getMinutes();
  if (hour === 0)
    hour = '00';
  else if (hour < 10)
    hour = '0' + hour;
  if (minutes === 0)
    minutes = '00';
  else if (minutes < 10)
    minutes = '0' + minutes;
  return hour + ':' + minutes;
};