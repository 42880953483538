import React, { useContext, useEffect, useState } from "react";

import '../../assets/css/experiences.css';

import { handleGetCompany, getAllExperiences, deleteExperience } from '../../apis/cae.endpoint';
import NoResultsComponent from '../../component/NoResultsComponent';
import EditExperiences from '../../component/EditComponents/EditExperiences';
import ExportExcelList from '../../component/ExportExcelList';

import addIconButton from '../../assets/imgs/addIconButton.svg';
import exportIconButton from '../../assets/imgs/exportIconButton.svg';
import searchIconButton from '../../assets/imgs/search_icon_button.svg';
import ButtonEditIcon from '../../assets/imgs/edit_icon_button.svg';
import EditIconList from '../../assets/imgs/edit_icon_list.svg';
import DeleteIconList from '../../assets/imgs/delete_icon_list.svg';
import ViewIconList from '../../assets/imgs/viewIconButton.svg';

import { PopupNewUser } from '../../component/PopupNewUser';
import { PopupNewExperience } from '../../component/PopupNewExperience';

import { handleFilterDataBySearch } from '../../utils/cae/handleSearch';

import { limitTextNum, limitTitle } from '../../utils/limitStr';
import ProgressBar from '../../component/ProgressBar';
import { PopupDeleteExperience } from "../../component/PopupDeleteExperience";
import { MyContext } from "./Cae";

export default function Experiences() {
  const [experiences, setExperiences] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [companyInfos, setCompanyInfos] = useState();
  const [search, setSearch] = useState('');
  const [editExperiences, setEditExperiences] = useState(false);
  const [experienceSelected, setExperienceSelected] = useState();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [viewStyle, setViewStyle] = useState(localStorage.getItem('userInfos') ? JSON.parse(localStorage.getItem('userInfos')).role === 'caeAdmin' : false);
  const [indexLastPopup, setIndexLastPopup] = useState(-1);
  const [showPopup, setShowPopup] = useState(localStorage.getItem('isNewUser') === 'true');
  const [expPopup, setExpPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [data, setData] = useState();
  const [optionsManager, setOptionsManager] = useState([{
    title: 'Editar',
    icon: EditIconList,
    function: handleManagerExperience,
  }, {
    title: 'Excluir',
    icon: DeleteIconList,
    function: handleOpenDeletePopup,
  }]);
  const labels = [
    { label: 'Experiencia', value: 'NomeExperiencia' },
    { label: 'Cidade', value: 'city' },
    { label: 'Empresa', value: 'company' },
    { label: 'SegmentosTuristicos', value: 'SegmentosTuristicos' },
    { label: 'ItensTuristicos', value: 'ItensTuristicos' },
    { label: 'FrequenciaExperiencia', value: 'FrequenciaExperiencia' },
    { label: 'AdequadaLGBT', value: 'AdequadaLGBT' },
    { label: 'AdequadaAcessibilidade', value: 'AdequadaAcessibilidade' },
    { label: 'AdequadaIdoso', value: 'AdequadaIdoso' },
    { label: 'AdequadaCrianca', value: 'AdequadaCrianca' },
    { label: 'RoteiroPertencente', value: 'RoteiroPertencente' },
    { label: 'RecebeEstrangeiro', value: 'RecebeEstrangeiro' },
    { label: 'Idiomas', value: 'Idiomas' },
    { label: 'ContratarAtendimentoIdioma', value: 'ContratarAtendimentoIdioma' },
    { label: 'ExemploAtendimentoOutroIdioma', value: 'ExemploAtendimentoOutroIdioma' },
    { label: 'Tipo', value: 'TipoExperiencia' },
    { label: 'DesenvolvimentoSustentavel', value: 'DesenvolvimentoSustentavel' },
    { label: 'Sustentabilidade', value: 'Sustentabilidade' },
    { label: 'ExperienciaInternacional', value: 'ExperienciaInternacional' },
    { label: 'ComercioInternacional', value: 'ComercioInternacional' },
    { label: 'AutorizacaoImagem', value: 'AutorizacaoImagem' },
    { label: 'Premiacao', value: 'Premiacao' },
    { label: 'Descricao', value: 'Descr' },
  ];
  const { userInfos } = useContext(MyContext);

  async function getExperiences() {
    if (userInfos.role === 'caeAdmin') {
      const experiences = await getAllExperiences();
      setExperiences(experiences);
      setFilteredData(experiences);
      setData(experiences);
      setViewStyle(true);
      setOptionsManager([{
        title: 'Visualizar',
        icon: ViewIconList,
        function: handleViewExperience,
      }]);
    } else {
      const uid = localStorage.getItem('uid');
      const company = await handleGetCompany(uid);
      setCompanyInfos(company);
      setExperiences(company.experiences);
      setFilteredData(company.experiences);
      setData(true);
    }
  }

  function handleManagerExperience(exp) {
    setExperienceSelected(exp);
    setEditExperiences(true);
  }

  function handleOpenDeletePopup(exp) {
    setExperienceSelected(exp);
    setDeletePopup(true);
  }

  async function handleDeleteExperience() {
    const uid = localStorage.getItem('uid');
    const company = await handleGetCompany(uid);

    await deleteExperience(company, company.experiences[experienceSelected], uid, experienceSelected);
    setDeletePopup(false);
    getExperiences();
  }


  function handleViewExperience(exp) {
    setExperienceSelected(exp);
    setViewStyle(true);
    setEditExperiences(true);
  }

  function handleGetNum() {
    const widthWindow = window.innerWidth;

    if (widthWindow > 1800)
      return 200;
    
    if (widthWindow > 1500)
      return 150;

    if (widthWindow > 1170)
      return 100;

    return 70;
  }

  function handleOptionsPopup(index) {
    const element = document.getElementById('option_' + index);
    const elementLast = document.getElementById('option_' + indexLastPopup);

    if (indexLastPopup === -1 || !isOpenPopup) {
      element.style = 'display: flex';
      setIndexLastPopup(index);
      setIsOpenPopup(true);
    } else if (isOpenPopup && index !== indexLastPopup) {
      elementLast.style = 'display: none';
      element.style = 'display: flex';
      setIndexLastPopup(index);
    } else {
      element.style = 'display: none';
      setIsOpenPopup(false);
    }
  }

  useEffect(() => {
    handleFilterDataBySearch(setFilteredData, experiences, ['NomeExperiencia', 'company', 'Descr', 'city'], search);
  }, [search]);

  useEffect(() => {
    if (userInfos)
      setViewStyle(userInfos.role === 'caeAdmin');
    else
      setViewStyle(false);
  }, [userInfos]);

  useEffect(() => {
    getExperiences();
  }, [userInfos]);

  return (
    <div className='Experiences'>
      {
        editExperiences ? <EditExperiences uid={localStorage.getItem('uid')} company={companyInfos} experience={experienceSelected !== null ? experiences[experienceSelected] : experienceSelected} setEditExperiences={setEditExperiences} index={experienceSelected} setCompanyInfos={setCompanyInfos} setExpPopup={setExpPopup} viewStyle={viewStyle} /> : (
          <div className='body-experiences'>
            <div className='div-header-experiences'>
              <h1>Experiências</h1>
            </div>
            { expPopup ? <PopupNewExperience setExpPopup={setExpPopup} /> : null }
            { showPopup ? <PopupNewUser setShowPopup={setShowPopup} /> : null }
            { deletePopup ? <PopupDeleteExperience setDeletePopup={setDeletePopup} deleteFunction={handleDeleteExperience} /> : null }
            <div className='div-create-experiences'>
              {
                viewStyle ? (
                  <ExportExcelList name={'Experîencia'} labels={labels} data={data} title={'Experîencia'} children={
                    <button className='add-new-experience' disabled={!data}>
                      <span>Exportar</span>
                      <img src={exportIconButton} />
                    </button>
                  } />
                ) : (
                  <button className='add-new-experience' onClick={() => handleManagerExperience(null)}>
                    <span>Criar experiência</span>
                    <img src={addIconButton} />
                  </button>
                )
              }
              <div className="div-search-experiences">
                <input type="text" placeholder="Pesquisar..." onChange={(e) => setSearch(e.target.value)} value={search} />
                <img src={searchIconButton} />
              </div>
            </div>
            { filteredData && filteredData.length > 0 ? (
            <div className='div_table_inventory_adm'>
              <div className="div-row-header-list">
                <div className="section-medium-list">Titulo</div>
                <div className="section-long-list table-remove-responsive-phone">Descrição curta</div>
                <div className="section-medium-list table-remove-responsive">Cidade</div>
                {
                  viewStyle ? 
                  <>
                    <div className="section-short-list table-remove-responsive">Preparação</div>
                    <div className="section-short-list table-remove-responsive">Promoção</div>
                    <div className="section-short-list table-remove-responsive">Score</div>
                  </> :
                    <>
                      <div className="section-short-list table-remove-responsive">Classificação</div>
                      <div className="section-short-list table-remove-responsive-phone">Empresa</div>
                    </>
                }
                <div className="section-option-list"></div>
              </div>
              {
                filteredData.map((exp, index) => {
                  return (
                    <div className="div-row-body-list" key={'Row_Experiences_' + index} >
                      <div className="section-medium-list">{limitTitle(exp.NomeExperiencia)}</div>
                      <div className="section-long-list table-remove-responsive-phone">{limitTextNum(exp.Descr, handleGetNum())}</div>
                      <div className="section-medium-list table-remove-responsive">{exp.city || companyInfos.city}</div>
                      {
                        !viewStyle ?
                        <>
                          <div className="section-short-list table-remove-responsive">Não Classificado</div>
                          <div className="section-short-list table-remove-responsive-phone">{exp.company || companyInfos.name}</div>
                        </> :
                        <>
                          <div className="section-short-list table-remove-responsive-phone">{exp.preparationPoints}</div>
                          <div className="section-short-list table-remove-responsive-phone">{exp.promotionPoints}</div>
                          <div className="section-short-list table-remove-responsive-phone">
                            <ProgressBar progress={exp.classification} />
                          </div>
                        </>
                      }
                      <div className="section-option-list">
                        <button onClick={() => handleOptionsPopup(index)}>
                          <img src={ButtonEditIcon} />
                        </button>
                        <div className={`popup-options-list${!viewStyle ? ' popup-options-list-larger' : ''}`} id={'option_' + index}>
                          {
                            optionsManager.map((op, indexOp) => {
                              return (
                                <div onClick={() => op.function(index)} key={'option_' + index + indexOp}>
                                  <span>{op.title}</span>
                                  <img src={op.icon} />
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </div>) : <NoResultsComponent title={'Experiências'} search={''} /> }
          </div>
        )
      }
    </div>
  );
}
