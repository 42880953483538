import React, { useState } from "react";

import '../assets/css/popupConfirmExperience.css';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export function PopupConfirmExperience({ action, setConfirmPopup, value, handleFunction, uid, company, index, setEditExperiences, setCompanyInfos, setExpPopup }) {
  const [progress, setProgress] = useState(0);
  const [textLoading, setTextLoading] = useState('Salvando documento...');
  const [isLoading, setIsLoading] = useState(false);

  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  function handleInitSaveDoc() {
    setIsLoading(true);
    handleFunction(company, value, uid, index, setEditExperiences, setCompanyInfos, setExpPopup, setProgress, setTextLoading);
  }
  
  return (
    <div className="PopupConfirmExperience">
      <div className="inner-popup-confirm-experience">
        <div className="div-align-info-popup-confirm-experience">
          <span>{action} Experiência?</span>
        </div>
        <div className="div-align-linear-progress-bar">
          {
            isLoading ? 
            <>
              <span className="info-span-popup-confirm-experience initial-info-span-popup-confirm-experience">{textLoading}</span>
              <LinearProgressWithLabel value={progress} />
            </> : <span className="info-span-popup-confirm-experience">Essa ação irá salvar os dados da sua experiência na base de dados. Você poderá visualizar essas informações posteriormente.</span>
          }
        </div>
        <div className="div-align-button-confirm-experience">
          <button onClick={() => setConfirmPopup(false)} disabled={isLoading}>Cancelar</button>
          <button onClick={() => handleInitSaveDoc()} disabled={isLoading}>Gravar</button>
        </div>
      </div>
    </div>
  );
}