import React from "react";

import '../assets/css/popupNewUser.css';

import DeleteIcon from '../assets/imgs/delete_icon_popup.svg';

export function PopupDeleteExperience({ setDeletePopup, deleteFunction }) {
  return (
    <div className="PopupNewUser">
      <div className="inner-popup-user">
        <div className="div-aling-img-popup-newuser">
          <img src={DeleteIcon} />
        </div>
        <div className="div-align-info-popup-newuser">
          <span>Excluir experiência?</span>
          <span>Essa é uma ação irreversível e apagará todos os dados preenchidos no cadastro da experiência. Deseja mesmo continuar?</span>
        </div>
        <div className="div-align-button-newuser div-align-button-delete-experience">
          <button onClick={() => setDeletePopup(false)}>Cancelar</button>
          <button onClick={() => deleteFunction()}>Deletar</button>
        </div>
      </div>
    </div>
  );
}