import React from "react";

import '../assets/css/popupNewUser.css';

import CompleteIcon from '../assets/imgs/complete_signin_icon.svg';

export function PopupNewExperience({ setExpPopup }) {
  return (
    <div className="PopupNewUser">
      <div className="inner-popup-user">
        <div className="div-aling-img-popup-newuser">
          <img src={CompleteIcon} />
        </div>
        <div className="div-align-info-popup-newuser">
          <span>Registro salvo!</span>
          <span>Sua experiência foi registrada com sucesso. Ela estará disponivel na listagem de experiências.</span>
        </div>
        <div className="div-align-button-newuser">
          <button onClick={() => setExpPopup(false)}>Continuar</button>
        </div>
      </div>
    </div>
  );
}