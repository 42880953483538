/* eslint-disable no-fallthrough */
import React, { useEffect, useState, useRef } from 'react';

import RemoveIcon from '../../assets/imgs/x_icon.svg';
import PDFIcon from '../../assets/imgs/pdf_icon.svg';

import '../../assets/css/reportDemand.css';

import { useReactToPrint } from 'react-to-print';
import Chart from 'react-apexcharts';

export default function ReportDemand({ answers, data, setReportPopup }) {
  const [charts, setCharts] = useState();
  const [typesCharts, setTypeCharts] = useState();
  const [keys, setKeys] = useState();
  const componentRef = useRef();

  function getValue(array, value) {
    for (let index = 0; index < array.length; index++)
      if (array[index][0] === value)
        return index;
    
    return -1;
  }

  function handleCep(charts, res, numeroPergunta) {
    let newCharts = { ...charts };

    const cep = res.toString().split(",");
    const city = cep[0];
    const state = cep[1];
    const country = cep[2];

    newCharts = handleText(newCharts, country, "País de origem", "Country", numeroPergunta);
    newCharts = handleText(newCharts, state, "Estado de origem", "State", numeroPergunta + 0.1);
    newCharts = handleText(newCharts, city, "Cidade de origem", "City", numeroPergunta + 0.2);

    return newCharts;
  }

  function handleMultipleChoise(charts, res, question, id, numeroPergunta) {
    res.map((response) => {
      charts = handleText(charts, response, question, id, numeroPergunta);
    });
    
    return charts;
  }

  function handleCitiesVisit(charts, res, question, id, numeroPergunta) {
    res.map((response) => {
      charts = handleText(charts, response.split(",")[2], question, id, numeroPergunta);
    });
    
    return charts;
  }

  function handleText(charts, res, question, id, numeroPergunta) {
    if (charts[id]) {
      const index = getValue(charts[id].stats, res);
      
      if (index !== -1)
        charts[id].stats[index][1] += 1;
      else
        charts[id].stats.push([res, 1]);
    } else
      charts[id] = { question: question, stats: [[res.toString(), 1]], order: numeroPergunta };
    
    return charts;
  }

  function handleItemAvaliation(charts, res, question, id, types, numeroPergunta) {
    const items = Object.keys(res);

    if (charts[id]) {
      types[id] += 1;
      items.map((item) => {
        const index = getValue(charts[id].stats, item);
        charts[id].stats[index][1] += res[item];
      });
    } else {
      charts[id] = { question: question, stats: [], order: numeroPergunta };
      types[id] = 1;
      items.map((item) => {
        charts[id].stats.push([item.toString(), res[item]]);
      });
    }

    return charts;
  }

  function handleSetData() {
    let charts = {};
    const types = {};

    answers.map((ans) => {
      const res = ans.resposta;

      switch (ans.tipoPergunta) {
        case 'cep':
          charts = handleCep(charts, res, ans.numeroPergunta);
          break;
        case 'escolha_multipla_outro':
        case 'escolha_multipla':
          charts = handleMultipleChoise(charts, res, ans.pergunta, ans.idPergunta, ans.numeroPergunta);
          types[ans.idPergunta] = 'pie';
          break;
        case 'atrativos':
          charts = handleMultipleChoise(charts, res, ans.pergunta, ans.idPergunta, ans.numeroPergunta);
          break;
        case 'nota_item':
          charts = handleItemAvaliation(charts, res, ans.pergunta, ans.idPergunta, types, ans.numeroPergunta);
          break;
        case 'cidadesVisitadas':
          charts = handleCitiesVisit(charts, res, ans.pergunta, ans.idPergunta, ans.numeroPergunta);
          types[ans.idPergunta] = 'pie';
          break;
        default:
          if (!['texto', 'textoDinamico', 'valor'].includes(ans.tipoPergunta))
            types[ans.idPergunta] = 'pie';
          charts = handleText(charts, res, ans.pergunta, ans.idPergunta, ans.numeroPergunta);
      }
    });

    const keys = Object.keys(charts);

    keys.sort(handleCompareOrder);

    keys.forEach((key) => {
      if (typeof types[key] === 'number') {
        for (let value = 0; value < charts[key].stats.length; value++)
          charts[key].stats[value][1] = (charts[key].stats[value][1] / types[key]).toFixed(1);

        types[key] = 'radar';
      }

      const newArray = charts[key].stats.sort(handleCompare);
      charts[key].stats = newArray;
    });

    setKeys(keys);
    setCharts(charts);
    setTypeCharts(types);
  }

  function handleCompare(a, b) {
    if (a[1] < b[1])
      return 1;

    if (a[1] > b[1])
      return -1;

    return 0;
  }

  function handleCompareOrder(a, b) {
    if (a.order < b.order)
      return -1;

    if (a.order > b.order)
      return 1;

    return 0;
  }

  const handlePDFGenerate = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Relatório Estudo de Demanda',
    pageStyle: `@page {
        size: 80mm 50mm;
        margin: 80px 80px;
      }

      @media all {
        .pagebreak {
          display: none;
        }
      }

      @media print {
        .pagebreak {
          page-break-before: always;
        }
        * {
          background: #F6F7FC;
          color: #000;
          text-shadow: none; 
          filter: none;
          -ms-filter: none;
        }
      }`, 
  });

  function handleGetSeriesChart(typeChart, values) {
    if (typeChart === 'pie')
      return values.map((val) => { return val[1]; });

    return [{
      name: 'Avaliações',
      data: values.map((val) => { return val[1]; }),
    }];
  }

  function handleGetIntervals(i, j, intervals) {
    const stack = [];
    stack.push([i, j]);
  
    while (stack.length > 0) {
      const [start, end] = stack.pop();
  
      if (end - start > 15) {
        const mid = Math.floor((start + end) / 2);
        stack.push([start, mid]);
        stack.push([mid + 1, end]);
      } else {
        intervals.push([start, end]);
      }
    }
  }
  

  function handleString(val) {
    const strText = val.toString();

    if (strText.length > 20)
      return strText.slice(0, 20) + '.';
    return strText;
  } 

  function handleRenderChart(i, j, key) {
    const intervals = [];
    handleGetIntervals(i, j, intervals);

    return intervals.map((inter, indexC) => {
      const values = charts[key].stats.filter((_, index) => index >= inter[0] && index <= inter[1]);
      return (
        <Chart
        options={{
          chart: {
            width: 420,
            type: typesCharts[key],
            height: 500,
          },
          labels: values.map((val) => { return handleString(val[0]); }),
          xaxis: {
            categories: values.map((val) => { return handleString(val[0]); }),
          },
        }}
        series={handleGetSeriesChart(typesCharts[key], values)}
        type={typesCharts[key]}
        width="500"
        height={500}
        key={'Chart_' + key + '_' + indexC}
      />
      );
    });
  }

  useEffect(() => {
    handleSetData();
  }, []);

  if (!charts || !typesCharts || !keys)
    return <></>;

  return (
    <div className="FilePDF">
      <div className="inner-popup-pdf">
        <div className="div-align-btn-pdf">
          <div className="div-btn-close-pdf" onClick={() => setReportPopup(false)}>
            <img className="img-close-pdf" src={RemoveIcon} />
            <span>Voltar</span>
          </div>
          <div className="div-btn-generate-pdf" onClick={handlePDFGenerate}>
            <img className="img-generate-pdf" src={PDFIcon} />
            <span>Download</span>
          </div>
        </div>
        <div className="div-align-body-pdf" id="div-align-body-pdf">
          <div className="div-body-pdf" ref={componentRef}>
            <div className="header-report-pdf">
              <span>Relatório de Estudo de Demanda</span>
              <span>{data.titulo}</span>
            </div>
            <div className="body-report-pdf">
              {
                keys.map((key, index) => {
                  return (
                    <div className="section-report-pdf" key={'section-report-pdf' + key + index}>
                      <div className="div-title-pdf">
                        <span>{charts[key].order + ' - ' + charts[key].question}</span>
                      </div>
                      <table className="div-table-pdf">
                        <thead className="header-table-pdf">
                          <tr>
                            <th>Respostas</th>
                            <th>Quantidade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            charts[key].stats.map((field, indexF) => {
                              return (
                                <tr className="row-table-pdf" key={charts[key].question + '_' + indexF}>
                                  <td>{field[0]}</td>
                                  <td>{field[1]}</td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                      {
                        typesCharts[key] && (charts[key].stats.length > 2 || typesCharts[key] !== 'radar') ? (
                          <div className="div-align-chart-pdf" id="div-align-chart-pdf">
                            { handleRenderChart(0, charts[key].stats.length, key) }
                          </div>
                        ) : null
                      }
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}