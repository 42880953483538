import React, { useEffect, useState } from 'react';
import firebase from '../config/Firebase';
import Loading from './Loading';
import VerifyPopup from './VerifyPopup';
import '../css/ListItinerary.css';
import Itinerary from './Itinerary';
import NoResultsComponent from './NoResultsComponent';

import GoBackIcon from '../assets/imgs/goback_icon.svg';

export default function ListItinerary({ setEditPopup, doc, setAction, userCity, handleEditItinerary, handleDeleteItinerary, verifyPopup, setVerifyPopup, mode, handleDeleteIt, selectedItem, setRouteID, search }) {
  const [itineraryList, setItineraryList] = useState([]);

  async function handleGetItinerary() {
    const docs = await firebase.firestore().collection('RoteirosTrechos').where('idRota', '==', doc.id).get();

    setItineraryList(docs.docs);
  }

  useEffect(() => {
    handleGetItinerary();
  }, []);

  if (!userCity) return <Loading />;

  return (
    <div className="ListItinerary">
      <div>
        <button onClick={() => setEditPopup('None')} className='btn_goback_ranking_cities'>
          <img src={GoBackIcon} alt='Icone indicando para voltar a pagina anterior' className='img_btn_goback_ranking_cities'/>
          <span>Voltar</span>
        </button>
      </div>
      <div className='div_title_inventory_adm'>
        <h1>Trechos da Rota/Ramal</h1>
      </div>
      <div className='div_create_inventory_adm'>
        <p className='create_inventory_adm' onClick={() => { setAction('Criar'); setEditPopup('Itinerary'); setRouteID(doc.id); }}>Criar Novo Trecho/Ramal</p>
      </div>
      { itineraryList && itineraryList.length > 0 ? (
        <div className="div_table_inventory_adm">
          <table className="table_inventory_adm">
            <tr className="row_table_inventory th_table_admin">
              <td className='column_title_table_inventory th_table_admin'>Trecho</td>
              <td className='column_desc_table_inventory th_table_admin'>Descrição</td>
              <td className='column_desc_table_inventory th_table_admin'>Status</td>
              <td className='column_desc_table_inventory th_table_admin'></td>
            </tr>
            {itineraryList.map((itinerary) => {
              return <Itinerary doc={itinerary} handleEditItinerary={handleEditItinerary} handleDeleteItinerary={handleDeleteItinerary}/>;
            })
            }
          </table>
        </div>
      ) : <NoResultsComponent title={'Trechos/Ramal'} search={search} />
      }
      { (verifyPopup && mode === 'Itinerary') && 
          <VerifyPopup title={'Excluir Roteiro'} message={'Tem certeza que gostaria de excluir esse roteiro?'} setVerifyPopup={setVerifyPopup} handleAction={handleDeleteIt} type={'RoteirosTrecho'} doc={selectedItem} />
      }
    </div>
  );
}