import React from "react";
import ReactExport from "react-export-excel";

export default function ExportExcelList({ name, data, labels, title, children }) {

  function handleValue(res, value) {
    const filtered = res[value] !== undefined ? res[value] : 'Não tem';

    if (value === 'Idiomas')
      return handleTextArray(filtered)

    if (typeof filtered === 'object')
      return handleTextObject(filtered);

    if (value === 'CadasturAtivo')
      return filtered ? 'Sim' : 'Não';

    return filtered;
  }
  
  function handleTextArray(obj) {
    const keys = Object.keys(obj);

    let text = '';

    keys.map((key) => {
      text = text + key + ': ' + obj[key] + ', ';
    });

    return text; 
  }

  function handleTextObject(obj) {
    const keys = Object.keys(obj);

    let text = '';

    keys.map((key) => {
      if (key !== 'Outro')
        text = text + key + ', ';
      else
        text = text + obj[key] + ', ';
    });

    return text;
  }
  
  return (
    <ReactExport.ExcelFile element={children}>
      <ReactExport.ExcelFile.ExcelSheet data={data} name={name} >
        {
          labels.map((label, index) => {
            return <ReactExport.ExcelFile.ExcelColumn key={title + label.label + index} label={label.label} value={(res) => handleValue(res, label.value)} />;
          })
        }
      </ReactExport.ExcelFile.ExcelSheet>
    </ReactExport.ExcelFile>
  );
}