import React, { useContext, useEffect, useState } from 'react';

import '../../assets/css/profileCae.css';

import DeafultPhoto from '../../assets/imgs/user_icon_navbar.svg';
import EditIconList from '../../assets/imgs/edit_icon_list.svg';
import LogoutIcon from '../../assets/imgs/logout_icon_header.svg';

import { TextField } from '../../component/Fields/TextField';
import { SelectField } from '../../component/Fields/SelectField';

import { Country, State, City } from 'country-state-city';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import firebase from '../../config/Firebase';
import ResizeImage from '../../component/ResizeImage';
import { getUser } from '../../apis/cae.endpoint';
import { MyContext } from './Cae';

export default function ProfileCae() {
  const [user, setUser] = useState(localStorage.getItem('userInfos') ? JSON.parse(localStorage.getItem('userInfos')) : {});
  const [userImage, setUserImage] = useState({ file: "", path: user.image });
  const [cropImage, setCropImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [editType, setEditType] = useState(true);
  const history = useHistory();
  const { setUserInfos } = useContext(MyContext);
  
  function getPlaces(places) {
    if (!places) return [];

    const filtered = [];

    places.map((plac) => {
      filtered.push(plac.name + ' (' + plac.isoCode + ')');
    });

    return filtered;
  }

  function getCities(cities) {
    const filtered = [];

    cities.map((city) => {
      filtered.push(city.name);
    });

    return filtered;
  }

  function handleEditImage(event) {
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    setCropImage(true);
  }

  function logout() {
    localStorage.removeItem('userInfos');
    localStorage.removeItem('uid');
    setTimeout(() => {
      firebase.auth().signOut().then(() => {
        history.push('/cae-login');
      });
    }, 500);
  }

  async function handleUpdateUser() {
    const uid = firebase.auth().currentUser.uid;
    await firebase.firestore().collection('users').doc(uid).set(user);
    localStorage.setItem('userInfos', JSON.stringify(user));
    setEditType(true);
    setUserInfos(user);
  }

  function handleCheckFields() {
    setErrorMessage("");

    const requiredField = ["name", "email", "city", "country", "state"];
    const fillFields = {
      name: "Nome",
      email: "Email",
      city: "Cidade",
      state: "Estado",
      country: "País",
    }

    for (let field = 0; field < requiredField.length; field++) {
      if (user[requiredField[field]] == "") {
        setErrorMessage("Preencha os campos obrigatórios: " + fillFields[requiredField[field]])
        return;
      }
    }

    handleUpdateUser();
  }

  async function handleSavePhotoProfile() {
    if (userImage.file === "") return;

    setUserInfos({ ...user, image: userImage.path });

    // Getting uid user
    const uid = firebase.auth().currentUser.uid;
    const ref = firebase.storage().ref(`users/${uid}`);
    await ref.put(userImage.file);

    const url = await ref.getDownloadURL(); 
    await firebase.firestore().collection('users').doc(uid).set({ ...user, image: url });
    await setUserInfos({ ...user, image: url });

    setSelectedImage(null);
  }

  useEffect(() => {
    handleSavePhotoProfile();
  }, [userImage]);

  return (
    <div className='ProfileCae'>
      <div className='container-profile-cae'>
        <header className='headear-profile-cae'>
          <h1>Meu Perfil</h1>
          <span>
            Essa pagina contem as informações de cadastro pessoais.
          </span>
        </header>
        <div className='body-profile-cae'>
          <div>
            <h3>Informações pessoais</h3>
          </div>
          <div className='div-form-profile-cae'>
            <div className='div-align-image-profile-cae'>
              <div className='div-image-profile'>
                <img src={userImage.path !== "" ? userImage.path : DeafultPhoto} />
              </div>
              <div className='div-aling-button-image-profile-cae'>
                <button className='button-edit-type-profile-cae' onClick={() => document.getElementById('edit-image-profile-cae').click()}>
                  <span>Editar foto</span>
                  <img src={EditIconList} alt="Ícone de edição de foto de perfil" /> 
                </button>
                <input className='input_file_inventory' type='file' id='edit-image-profile-cae' accept='.jpg,.png' onChange={(event) => handleEditImage(event)} />
                <button onClick={() => logout()}>
                  <span>Sair</span>
                  <img src={LogoutIcon} alt="Ícone para sair do sistema" /> 
                </button>
              </div>
            </div>
            { cropImage ? 
              <ResizeImage imagePath={selectedImage} setCropImage={setCropImage} setFinalImage={setUserImage} /> 
              : null 
            }
            <div>
              <div className="form-edit-register">
                <TextField
                  title={"Nome"}
                  fieldForm={"name"}
                  placeholder={"Nome..."}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                  value={user.name || ""}
                  style={{ width: "100%" }}
                  disabled={editType}
                  className="input-profile-cae"
                />
                <TextField
                  title={"Email"}
                  fieldForm={"email"}
                  placeholder={"Email..."}
                  onChange={(e) => setUser({ ...user, email: e.target.value })}
                  value={user.email || ""}
                  style={{ width: "100%" }}
                  disabled={editType}
                  className="input-profile-cae"
                />
                <SelectField
                  title={'País'}
                  values={getPlaces(Country.getAllCountries())}
                  placeholder={'Selecione o país'}
                  fieldForm={'country'}
                  style={{ width: '170px' }}
                  setValue={setUser}
                  value={user}
                  defaultValue={user.country}
                  disabled={editType}
                  className="input-profile-cae"
                />
                <SelectField
                  title={'Estado'}
                  values={getPlaces(State.getStatesOfCountry(user.country ? user.country.slice(user.country.length - 3, user.country.length - 1) : ""))}
                  placeholder={'Selecione o estado'}
                  fieldForm={'state'}
                  style={{ width: '180px' }}
                  setValue={setUser}
                  value={user}
                  defaultValue={user.state}
                  disabled={editType}
                  className="input-profile-cae"
                />
                <SelectField
                  title={'Cidade'}
                  values={getCities(City.getCitiesOfState(user.country ? user.country.replace('(', ',').replace(')', ',').split(',')[1] : "", user.state ? user.state.replace('(', ',').replace(')', ',').split(',')[1]: ""))}
                  placeholder={'Selecione a cidade'}
                  fieldForm={'city'}
                  style={{ width: '200px' }}
                  setValue={setUser}
                  value={user}
                  defaultValue={user.city}
                  disabled={editType}
                  className="input-profile-cae"
                />
                <TextField
                  title={"Telefone"}
                  fieldForm={"phoneNumber"}
                  placeholder={"Telefone..."}
                  onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
                  value={user.phoneNumber || ""}
                  style={{ width: "100%" }}
                  disabled={editType}
                  className="input-profile-cae"
                />
              </div>
              <div className='div-align-edit-info-profile-cae'>
                { errorMessage !== "" ? <span className="error-message-profile-cae">{errorMessage}</span> : null }
                {
                  editType ? <button className='button-edit-type-profile-cae' onClick={() => setEditType(false)}>
                    <span>Editar informações</span>
                    <img src={EditIconList} alt="Ícone de edição de foto de perfil" /> 
                  </button> :
                  <div className='div-align-buttons-profile-cae'>
                    <button onClick={() => { setUser(localStorage.getItem('userInfos') ? JSON.parse(localStorage.getItem('userInfos')) : {}); setEditType(true)}}>
                      <span>Cancelar</span>
                    </button>
                    <button onClick={() => handleCheckFields()}>
                      <span>Salvar</span>
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}