import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

// CSS import
import '../assets/css/caeLogin.css';

import { resetPassword } from '../apis/cae.endpoint';

// Assets imports
import UserIcon from '../assets/imgs/user_icon.svg';
import CaeIcon from '../assets/imgs/cae_background_login.png';
import LogoDestinosInteligentes from '../assets/imgs/footer_logo_destinos.png';

// Components imports
import TextField from '../component/CaeLogin/TextField';
import FooterLoginCae from '../component/CaeLogin/FooterLoginCae';

export default function CaeForgotPassword() {
  const [email, setEmail] = useState('');
  const [messageError, setMessageError] = useState('');
  const [message, setMessage] = useState('');
  const history = useHistory();

  async function handleResetPassword() {
    const error = await resetPassword(email);

    if (error !== '')
      setMessageError('Email inválido!');
    else {
      setMessageError('');
      setMessage('Link de redefinição de senha enviado para o email!');
    }
  }

  return (
    <div className="CaeLogin">
      <div className="main-container-cae-login">
        <div className="container-cae-login">
          <div className="header-container-cae-login">
            <img src={CaeIcon} />
          </div>
          <div className="title-container-cae-login">
            <h1>RECUPERA SENHA</h1>
            <span>Preencha o campo abaixo para enviar um link de redefinição de senha para seu email.</span>
          </div>
          <div className="div-align-fields-cae-login">
            <TextField
              placeholder={'Email'}
              icon={UserIcon}
              type={'text'}
              onChange={(e) => setEmail(e.target.value)}
            />
            { messageError !== '' ? <span className="error-message-form-cae error-message-login">{messageError}</span> : null }
            { message !== '' ? <span className="message-notify-reset-password">{message}</span> : null }
          </div>
          <div className="div-button-cae-login">
            <button onClick={() => handleResetPassword()}>Enviar</button>
          </div>
          <div className="hr-div-cae-login" />
          <div className="div-create-account-cae-login">
            <span><a onClick={() => history.push('/cae-login')}>Voltar para o Login</a></span>
          </div>
          <div className="footer-logo-destinos">
            <span>Powered by</span>
            <img src={LogoDestinosInteligentes} />
          </div>
        </div>
        <div className="container-cae-login" />
      </div>
      <FooterLoginCae />
    </div>
  );
}