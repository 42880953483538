import React, { useContext, useEffect, useState } from 'react';

import '../../assets/css/experiences.css';

import NoResultsComponent from '../../component/NoResultsComponent';
import SpinButton from '../../component/SpinButton';
import ExportExcelList from '../../component/ExportExcelList';

import { getAllCompanies, handleGetCompany } from '../../apis/cae.endpoint';

import { handleFilterDataBySearch } from '../../utils/cae/handleSearch';

import searchIconButton from '../../assets/imgs/search_icon_button.svg';
import ButtonEditIcon from '../../assets/imgs/edit_icon_button.svg';
import ViewIconList from '../../assets/imgs/viewIconButton.svg';
import exportIconButton from '../../assets/imgs/exportIconButton.svg';
import ExportIcon from '../../assets/imgs/exportIcon.svg';
import EditIconList from '../../assets/imgs/edit_icon_list.svg';

import EditCompanies from '../../component/EditComponents/EditCompanies';
import { MyContext } from './Cae';

export default function Agencys() {
  const [companies, setCompanies] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [indexLastPopup, setIndexLastPopup] = useState(-1);
  const [editCompanies, setEditCompanies] = useState(false);
  const [companySelected, setCompanySelected] = useState();
  const [search, setSearch] = useState('');
  const [data, setData] = useState();
  const [viewStyle, setViewStyle] = useState();
  const [optionsManager, setOptionsManager] = useState([{
    title: 'Exportar',
    icon: ExportIcon,
    function: (() => {}),
  }, {
    title: 'Visualizar',
    icon: ViewIconList,
    function: ((index) => handleViewCompany(index)),
  }]);
  const labels = [
    { label: 'NomeEmpresa', value: 'name' },
    { label: 'NomeResponsavel', value: 'managerName' },
    { label: 'EmailResponsavel', value: 'email' },
    { label: 'RamosAtuacao', value: 'type' },
    { label: 'OutroTipo', value: 'typeOther' },
    { label: 'Pais', value: 'country' },
    { label: 'Estado', value: 'state' },
    { label: 'Cidade', value: 'city' },
    { label: 'CNPJ', value: 'cnpj' },
    { label: 'CadasturAtivo', value: 'CadasturAtivo' },
    { label: 'Site', value: 'site' },
    { label: 'WhatsApp', value: 'whatsapp' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'Youtube', value: 'youtube' },
    { label: 'Tiktok', value: 'tiktok' },
    { label: 'OutroLink', value: 'otherLinks' },    
  ];
  const labelsExperience = [
    { label: 'Experiencia', value: 'NomeExperiencia' },
    { label: 'Cidade', value: 'city' },
    { label: 'Empresa', value: 'company' },
    { label: 'SegmentosTuristicos', value: 'SegmentosTuristicos' },
    { label: 'ItensTuristicos', value: 'ItensTuristicos' },
    { label: 'FrequenciaExperiencia', value: 'FrequenciaExperiencia' },
    { label: 'AdequadaLGBT', value: 'AdequadaLGBT' },
    { label: 'AdequadaAcessibilidade', value: 'AdequadaAcessibilidade' },
    { label: 'AdequadaIdoso', value: 'AdequadaIdoso' },
    { label: 'AdequadaCrianca', value: 'AdequadaCrianca' },
    { label: 'RoteiroPertencente', value: 'RoteiroPertencente' },
    { label: 'RecebeEstrangeiro', value: 'RecebeEstrangeiro' },
    { label: 'Idiomas', value: 'Idiomas' },
    { label: 'ContratarAtendimentoIdioma', value: 'ContratarAtendimentoIdioma' },
    { label: 'ExemploAtendimentoOutroIdioma', value: 'ExemploAtendimentoOutroIdioma' },
    { label: 'Tipo', value: 'TipoExperiencia' },
    { label: 'DesenvolvimentoSustentavel', value: 'DesenvolvimentoSustentavel' },
    { label: 'Sustentabilidade', value: 'Sustentabilidade' },
    { label: 'ExperienciaInternacional', value: 'ExperienciaInternacional' },
    { label: 'ComercioInternacional', value: 'ComercioInternacional' },
    { label: 'AutorizacaoImagem', value: 'AutorizacaoImagem' },
    { label: 'Premiacao', value: 'Premiacao' },
    { label: 'Descricao', value: 'Descr' },
  ];
  const { userInfos } = useContext(MyContext);

  function handleViewCompany(exp) {
    setCompanySelected(exp);
    setEditCompanies(true);
  }
  
  function handleExportExperiencesFromAgency(comp, comps, data) {
    const name = comps[comp].name;
    const city = comps[comp].city;
    let newData = [];

    data.forEach((comp) => {
      if (comp.name === name) {
        const taggedExp = [];  
        comp.experiences.forEach((exp) => {
          taggedExp.push({ ...exp, company: name, city: city });
        });
        newData = newData.concat(taggedExp);
      }
    });

    return newData;
  }

  function handleManagerCompany(comp) {
    setCompanySelected(comp);
    setEditCompanies(true);
  }

  function handleOptionsPopup(index) {
    const element = document.getElementById('option_' + index);
    const elementLast = document.getElementById('option_' + indexLastPopup);

    if (indexLastPopup === -1 || !isOpenPopup) {
      element.style = 'display: flex';
      setIndexLastPopup(index);
      setIsOpenPopup(true);
    } else if (isOpenPopup && index !== indexLastPopup) {
      elementLast.style = 'display: none';
      element.style = 'display: flex';
      setIndexLastPopup(index);
    } else {
      element.style = 'display: none';
      setIsOpenPopup(false);
    }
  }

  function handleCadasturText(cadastur) {
    if (cadastur === undefined) return 'Não respondeu';

    if (cadastur)
      return 'Sim';

    return 'Não';
  }

  async function getCompanies() {
    if (userInfos.role === 'caeAdmin') {
      const companies = await getAllCompanies();
      const newData = companies.map((comp) => {
        return comp.data();
      });
      
      setCompanies(newData);
      setFilteredData(newData);
      setData(newData);
      setViewStyle(true);
    } else {
      const uid = localStorage.getItem('uid');
      const company = await handleGetCompany(uid);
      setFilteredData([company]);
      setCompanies([company]);
      setOptionsManager([{
        title: 'Editar',
        icon: EditIconList,
        function: ((index) => handleManagerCompany(index)),
      }]);
    }
  }

  useEffect(() => {
    handleFilterDataBySearch(setFilteredData, companies, ['name', 'managerName', 'city', 'type'], search, true);
  }, [search]);

  useEffect(() => {
    if (userInfos)
      setViewStyle(userInfos.role === 'caeAdmin');
    else
      setViewStyle(false);
  }, [userInfos]);

  useEffect(() => {
    getCompanies();
  }, [editCompanies, userInfos]);

  return (
    <div className='Experiences'>
      {
        editCompanies ? <EditCompanies uid={localStorage.getItem('uid')} company={companies[companySelected]} setEditCompanies={setEditCompanies} index={companySelected} setCompanyInfos={setCompanySelected} viewStyle={viewStyle} /> : (
          <div className='body-experiences'>
            <div className='div-header-experiences'>
              <h1>Empresas</h1>
            </div>
            {
              viewStyle ? (
                <div className='div-create-experiences'>
                  <ExportExcelList name={'Exportar'} labels={labels} data={data} title={'Agência'} children={
                    (
                      <button className='add-new-experience' disabled={!data}>
                        {
                          !data ? <SpinButton /> : (
                            <>
                              <span>Exportar</span>
                              <img src={exportIconButton} alt="Icone de exportar experiências" />
                            </>
                          )
                        }
                      </button>
                    )}
                  />
                  <div className="div-search-experiences">
                    <input type="text" placeholder="Pesquisar..." onChange={(e) => setSearch(e.target.value)} />
                    <img src={searchIconButton} alt="Ícone de lupa para pesquisar" />
                  </div>
                </div>
              ) : null
            }
            { filteredData && filteredData.length > 0 ? (
            <div className='div_table_inventory_adm'>
              <div className="div-row-header-list">
                <div className="section-medium-list">Nome da Empresa</div>
                <div className="section-medium-list">Nome do Responsável</div>
                <div className="section-medium-list">Cidade</div>
                <div className="section-long-list table-remove-responsive-phone">Ramos de Atuação</div>
                <div className="section-short-list table-remove-responsive">Cadastur Ativo</div>
                <div className="section-short-list table-remove-responsive">Experiências cadastradas</div>
                <div className="section-option-list"></div>
              </div>
              {
                filteredData.map((age, index) => {
                  return (
                    <div className="div-row-body-list" key={'Row_Experiences_' + index} >
                      <div className="section-medium-list">{age.name}</div>
                      <div className="section-medium-list">{age.managerName}</div>
                      <div className="section-medium-list">{age.city}</div>
                      <div className="section-long-list table-remove-responsive-phone">{age.type}</div>
                      <div className="section-short-list table-remove-responsive">{handleCadasturText(age.CadasturAtivo)}</div>
                      <div className="section-short-list table-remove-responsive">{age.experiences.length}</div>
                      <div className="section-option-list">
                        <button onClick={() => handleOptionsPopup(index)}>
                          <img src={ButtonEditIcon} alt="Ícone de opções para uma empresa" />
                        </button>
                        <div className={`popup-options-list${viewStyle ? ' popup-options-list-larger' : ''}`} id={'option_' + index}>
                          {
                            optionsManager.map((op, indexOp) => {
                              return (
                                op.title !== 'Exportar' || !data ? (
                                  <div onClick={() => op.function(index)} key={'option_' + index + indexOp}>
                                    <span>{op.title}</span>
                                    <img src={op.icon} alt="Ícone de ação para uma empresa" />
                                  </div>) : <ExportExcelList name={'Exportar'} labels={labelsExperience} data={handleExportExperiencesFromAgency(index, companies, data)} title={'Experîencia/Unica'} children={
                                    <div key={'option_' + index + indexOp}>
                                      <span>{op.title}</span>
                                      <img src={op.icon} alt="Ícone de ação para uma empresa" />
                                    </div>
                                  }/>
                              );
                            })
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </div>) : <NoResultsComponent title={'Empresas'} search={''} /> }
          </div>
        )
      }
    </div>
  );
}