import React, { useEffect, useState } from 'react';
import '../css/AtractionRoutes.css';
import { limitStr } from '../utils/limitStr';
import firebase from '../config/Firebase';

export default function RoutesList({ doc, handleEdit, handleDelete, handleListItinerary, handleChange }) {
  const [publish, setPublish] = useState();
  const types = {
    Attraction: 'Atrativo',
    Adventure: 'Aventura',
    Scenic: 'Cênica',
    Beer: 'Cerveja',
    'Bicycle Tourism': 'Cicloturismo',
    Shopping: 'Compras',
    Ecotourism: 'Ecoturismo',
    Gastronomy: 'Gastronomia',
    'Motorcycle Tourism': 'Mototurismo',
    Religious: 'Religioso',
    Rural: 'Rural',
    Wine: 'Vinho',
  };

  useEffect(() => {
    handleSetPublish();
  }, []);

  async function handleSetPublish() {
    const docInfo = await firebase.firestore().collection('Roteiros').doc(doc.id).get();

    setPublish(!!docInfo.data().Active);
  }

  async function handlePublish() {
    await firebase.firestore().collection('Roteiros').doc(doc.id).update({
      Active: !publish,
    });

    setPublish(!publish);
    handleChange();
  }

  return (
    <tr className="row_table_inventory">
      <td className="title_table_inventory">{doc.data().Title}</td>
      <td className="desc_table_inventory">{limitStr(doc.data()['Short Descr'])}</td>
      <td className="desc_table_inventory">{types[doc.data().Tipo] || doc.data().Tipo }</td>
      <td className="title_table_inventory">{doc.data().Roteiros ? doc.data().Roteiros.length : 0}</td>
      <td className="column_min_table_inventory">{doc.data().City}</td>
      <td className="column_min_table_inventory">{doc.data().Active ? 'Ativo' : 'Desativado'}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc, 'Rotas')}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handleListItinerary(doc)}>Ver Trechos Rota/Ramal</button>
          {
            doc.data().Roteiros && doc.data().Roteiros.length > 0 ?
              <button className='btn_mod_adm' onClick={() => handlePublish()}>{ publish ? 'Despublicar' : 'Publicar' }</button>
              : null
          }
          <button className='btn_mod_adm' onClick={() => handleDelete(doc)}>Excluir</button>
        </div>
      </td>
    </tr>
  );
}
