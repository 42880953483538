import React, { useState, useEffect } from 'react';
import firebase from '../config/Firebase';
import InputCheckboxCustom from './InputComponents/InputCheckBoxCustom';

export default function CorrectionsList({ doc, handleEdit, handleAddDocList, handleDelete }) {
  const [objFeed, setObjFeed] = useState();
  const [date, setDate] = useState('Não há');

  async function handleData() {
    const data = doc.data();

    const collecs = {
      Evento: 'Events',
      Inventario: 'Attractions',
      Atrativo: 'Attractions',
    };

    const docFeed = await firebase.firestore().collection(collecs[data.tipo]).doc(data.ID).get();

    if (docFeed.data())
      setObjFeed(docFeed.data().Title);
    else
      setObjFeed('Não há');

    if (data.data) {
      const dateTxt = new Date(data.data);

      if (dateTxt.getDate())
        setDate(`${dateTxt.getDate()}/${dateTxt.getMonth() + 1}/${dateTxt.getFullYear()}`);
    }
  }

  useEffect(() => {
    if (doc.data().ID && doc.data().tipo)
      handleData();
    else
      setObjFeed('Não há');
  }, []);

  if (!objFeed || !date) {
    return <></>;
  }

  return (
    <tr className="row_table_inventory body_table">
      <td className="check_table_inventory"><InputCheckboxCustom handleAction={handleAddDocList} doc={doc} listPend={false} nameCheck={'checkbox_corrections'} /></td>
      <td className="title_table_inventory">{doc.data().titulo || 'Não há'}</td>
      <td className="title_table_inventory">{date}</td>
      <td className="desc_table_inventory">{doc.data().feedback}</td>
      <td className="title_table_inventory">{objFeed}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          {
            doc.data().ID && doc.data().tipo ? <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Ver</button> : null
          }
          <button className='btn_mod_adm' onClick={() => handleDelete(doc)}>Concluir</button>
        </div>
      </td>
    </tr>
  );
}