import React from 'react';

import '../assets/css/progressBar.css';

export default function ProgressBar({ progress }) {
  const widthRed = Math.min(progress, 59);
  const widthYellow = Math.min((progress - 59) > 0 ? (progress - 59) : 0, 20);
  const widthGreen = Math.min((progress - 79) > 0 ? (progress - 79) : 0, 21);
  return (
    <div className='ProgressBar'>
      <div className="div-align-progressbar">
        <div className="div-progress-bar red-progress-bar" style={{ width: `${widthRed}%` }} />
        <div className="div-progress-bar yellow-progress-bar" style={{ width: `${widthYellow}%` }} />
        <div className="div-progress-bar green-progress-bar" style={{ width: `${widthGreen}%` }} />
      </div>
      <div className="div-progress-pointer" style={{ left: `calc(${progress}% - 26.5px)` }}>
        <span>{progress}</span>
      </div>
      <div className="div-progress-pointer-base" style={{ left: `${progress - 4}%` }}/>
    </div>
  );
}