import React, { useState, useEffect } from 'react';

// Assets imports
import AddIcon from '../../assets/imgs/add_icon_template.svg';
import GoBackIcon from '../../assets/imgs/goback_icon.svg';
import IconTemplateDoc from '../../assets/imgs/docTemplate_icon.svg';

// CSS imports
import '../../assets/css/EditComponents/editTemplates.css';
import Loading from '../Loading';
import { getAllTemplate } from '../../apis/templates.endpoint';

export default function EditTemplates({ object, refDoc, setTemplatePopup, setEditPopup, setAction, setSelectedItem, setTemplate, city }) {
  const [templates, setTemplates] = useState();
  
  async function handleGetTemplates() {
    const res = await getAllTemplate(city);
    setTemplates(res.data.templates);
  }

  async function createObject(obj) {
    setAction('Criar');

    setSelectedItem(undefined);
    setTemplate(obj);
    setEditPopup(true);
    setTemplatePopup(false);
  }

  useEffect(() => {
    handleGetTemplates();
  }, [city]);

  if (!templates) {
    return <Loading />;
  }

  return (
    <div className='EditTemplates'>
      <div className='div_align_body_edit_templates'>
        <div className='div_back_templates'>
          <button onClick={() => setTemplatePopup(false)} className='btn_goback_templates'>
            <img src={GoBackIcon} alt='Icone indicando para voltar a pagina anterior' className='img_btn_goback_ranking_cities'/>
            <span>Voltar</span>
          </button>
        </div>
        <div className='header_edit_template'>
          <h1>
            Criar novo { object }
          </h1>
          <span>
            Escolha um template já existente para facilitar na criação, ou começe criando do zero.
          </span>
        </div>
        <div className='body_edit_template'>
          <div className='card_template_default' onClick={() => createObject(undefined)}>
            <img src={AddIcon} alt='Ícone de adicionar' />
            <span>Novo {object}</span>
          </div>
          {
            templates.map((temp, index) => {
              return (
                <div className='card_template' onClick={() => createObject(temp.template)} key={temp.title + index + 'template'} >
                  <span>{temp.title}</span>
                  <div className='div_template_doc'>
                    <div className='div_img_templateDoc'>
                      <img src={IconTemplateDoc} alt='Ícone de um documento' />
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}