import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { Country, State, City } from 'country-state-city';

// CSS import
import '../assets/css/caeRegister.css';

import FooterLoginCae from '../component/CaeLogin/FooterLoginCae';
import FieldFormCae from '../component/CarRegister/FieldFormCae';

import { checkFields, checkAgencyFields } from '../utils/caeRegister/registerManagerCae';

import { createUserAndAgency } from '../apis/cae.endpoint';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CaeRegister() {
  const [step, setStep] = useState(1);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [passwordConfirmed, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [userInfos, setUserInfos] = useState({
    email: '',
    name: '',
    country: 'Brazil (BR)',
    state: '',
    city: '',
    password: '',
  });
  const [agencyInfos, setAgencyInfos] = useState({
    managerName: '',
    name: '',
    type: '',
    state: '',
    city: '',
    country: 'Brazil (BR)',
    cnpj: '',
  });
  const [fieldsRequire] = useState(Object.keys(userInfos));
  const [fieldsAgencyRequire] = useState(Object.keys(agencyInfos));
  const history = useHistory();

  const handleSetData = (userInfos, uid) => {
    localStorage.setItem('userInfos', JSON.stringify(userInfos));
    localStorage.setItem('uid', uid);
    localStorage.setItem('isNewUser', 'true');
    history.push('/cae/experiencias');
  };

  function getPlaces(places) {
    if (!places) return [];

    const filtered = [];

    places.map((plac) => {
      filtered.push(plac.name + ' (' + plac.isoCode + ')');
    });

    return filtered;
  }

  function getCities(cities) {
    const filtered = [];

    cities.map((city) => {
      filtered.push(city.name);
    });

    return filtered;
  }

  useEffect(() => {
    setUserInfos({ ...userInfos, state: '', city: '' });
  }, [userInfos.country]);

  useEffect(() => {
    setUserInfos({ ...userInfos, city: '' });
  }, [userInfos.state]);

  useEffect(() => {
    setAgencyInfos({ ...agencyInfos, state: '', city: '' });
  }, [agencyInfos.country]);

  useEffect(() => {
    setAgencyInfos({ ...agencyInfos, city: '' });
  }, [agencyInfos.state]);
  
  if (step === 0) {
    return <Redirect to={'/cae-login'} />;
  }

  if (step === 1) {
    return (
      <div className="CaeRegister">
        <div className="main-container-cae-register">
          <div className="header-container-cae-register">
            <span>Criar conta</span>
            <span>Criando experiências</span>
          </div>
          <div className="div-title-cae-register">
            <h1>Oi, eu sou o Caê, Cadastro Automático de Experiências da Embratur!</h1>
          </div>
          <div className="div-body-cae-register">
            <p>Sou um formulário on-line para que negócios e destinos turísticos cadastrem suas experiências direcionadas ao turista estrangeiro!</p>
            <p>Fui criado pela Embratur - Agência Brasileira de Promoção Internacional do Turismo :)</p>
            <p> Se você é uma agência de viagens, operadora de turismo, estabelecimento de receptivo, meio de hospedagem, restaurante, bar, coletivo, guia de turismo, condutor de turismo de aventura ou outro negócio de turismo, se gere um atrativo, bem como áreas afins que ofereçam alguma experiência imperdível, este formulário é para você!</p>
            <p>Mas o que é uma experiência turística? Para os turistas de hoje, um produto ou serviço é o básico. Eles buscam experiências que conectam os sentidos, geram emoção e engajamento. Uma experiência, portanto, pode ser compartilhada e fica guardada na memória para sempre.</p>
            <p>Além disso, quando falamos no turista estrangeiro, nos referimos a alguém que, na maioria das vezes, viaja muitas horas para chegar ao seu destino, fala outro idioma, desconhece os hábitos, a cultura, a natureza, os riscos e o nosso jeito de ser. Por isso, quanto mais completa, segura e sustentável for a sua experiência, maior a probabilidade dela estar preparada para este público!</p>
            <p>O Brasil é um país com muitas singularidades. É também plural e diverso com muitas iniciativas inclusivas - nos rostos, nas cores, na biodiversidade, na religiosidade, na cultura, nos fazeres, nos saberes e nos sabores. Este mosaico de experiências espalhadas nos quatro cantos do país podem ser combinadas para diferentes perfis de público, em diferentes estações. Portanto, se sua empresa ou coletivo já oferecem esse tipo de experiência, faça seu cadastro agora mesmo! </p>
            <p>Em apenas 5 a 8 minutos você preencherá esse cadastro, composto de 2 seções: Dados da empresa/empreendedor e Dados da Experiência. Você pode preencher mais de uma experiência e o tempo de resposta vai depender muito de quantas experiências quer cadastrar. Super fácil e rápido !</p>
            <p>Ah... e mesmo que sua experiência ainda não esteja totalmente preparada, seu cadastro vai nos ajudar a entender o que ainda pode ser aprimorado na nossa hospitalidade.</p>
            <p>Ficou com alguma dúvida? Compartilhe comigo pelo e-mail cae@embratur.com.br</p>
            <p>Vamos começar?</p>
            <div className="line-break-cae-register" />
            <div className="div-buttons-cae-register">
              <button onClick={() => setStep(0)}>Voltar</button>
              <button onClick={() => setStep(2)}>Próximo</button>
            </div>
          </div>
        </div>
        <FooterLoginCae />
      </div>
    );
  }

  if (step === 2) {
    return (
      <div className="CaeRegister">
        <div className="main-container-cae-register">
          <div className="header-container-cae-register">
            <span>Criar conta</span>
            <span>Criando experiências</span>
          </div>
          <div className="div-title-cae-register">
            <h1>Lei Geral de Proteção e Integração de Dados.</h1>
          </div>
          <div className="div-body-cae-register">
            <p>LGPD - Em observância à Lei nº. 13.709/18 - Lei Geral de Proteção de Dados Pessoais e demais normativas aplicáveis sobre proteção de Dados Pessoais, manifesto-me de forma informada, livre, expressa e consciente, no sentido de autorizar a Embratur a realizar o tratamento de meus Dados Pessoais para as ﬁnalidades e de acordo com as condições aqui estabelecidas.</p>
            <p>Os meus Dados Pessoais poderão ser utilizados pela Embratur para:</p>
            <ul>
              <li>Cumprir as obrigações contratuais, legais e regulatórias da Embratur, em razão de suas atividades;</li>
              <li>Execução de seus Programas e prestação de serviços;</li>
              <li>Fomentar, desenvolver e melhorar soluções para o desenvolvimento de negócios;</li>
              <li>Oferecer produtos e serviços que sejam de interesse e estratégia da Agência;</li>
              <li>Realizar pesquisas com os clientes;</li>
              <li>Realizar a comunicação oﬁcial pela Embratur ou por seus prestadores de serviço, por meio de quaisquer canais de comunicação (telefone, e-mail, SMS, WhatsApp, etc.);</li>
            </ul>
            <div className="line-break-cae-register" />
            <div className="div-terms-accepted">
              <label className='label-terms-accepted'>
                <input type="checkbox" id="register-terms" onChange={() => setTermsAccepted(!termsAccepted)} checked={termsAccepted} />
                <span className='checkmark-terms'></span>
              </label>
              <label htmlFor="register-terms" className="label-terms-text">Eu autorizo o uso dos meus dados</label>
            </div>
            <div className="div-buttons-cae-register">
              <button onClick={() => setStep(1)}>Voltar</button>
              <button onClick={() => setStep(3)} disabled={!termsAccepted}>Próximo</button>
            </div>
          </div>
        </div>
        <FooterLoginCae />
      </div>
    );
  }

  if (step === 3) {
    return (
      <div className="CaeRegister">
        <div className="main-container-cae-register">
          <div className="header-container-cae-register">
            <span>Criar conta</span>
            <span className="subspan-haeder-cae-register">Criando experiências</span>
          </div>
          <div className="div-title-cae-register">
            <h1>Informações pessoais</h1>
            <h6>Cadastro</h6>
          </div>
          <div className="div-body-cae-register">
            <p>Abaixo estão os campos de informações que são necessarios para efetuar a criação da sua conta. Ao preenche-los efetuaremos seu cadastro.</p>
            <div className="line-break-cae-register" />
            <div className="div-form-cae-register">
              <span>Os campos com * são obrigatórios.</span>
              { errorMessage !== '' ? <span className="error-message-form-cae">{errorMessage}</span> : null }
              <FieldFormCae
                label={'Email*'}
                type={'text'}
                width={'100%'}
                value={userInfos.email}
                onChange={(e) => setUserInfos({ ...userInfos, email: e.target.value })}
                placeholder={'Preencha com o email'}
              />
              <FieldFormCae
                label={'Senha*'}
                type={'password'}
                width={'48%'}
                value={userInfos.password}
                onChange={(e) => setUserInfos({ ...userInfos, password: e.target.value })}
                placeholder={'Digite sua senha'}
                />
              <FieldFormCae
                label={'Confirmar senha*'}
                type={'password'}
                width={'48%'}
                value={passwordConfirmed}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={'Digite sua senha novamente'}
                />
              <FieldFormCae
                label={'Nome*'}
                type={'text'}
                width={'100%'}
                value={userInfos.name}
                onChange={(e) => setUserInfos({ ...userInfos, name: e.target.value })}
                placeholder={'Digite seu nome'}
                />
              <FieldFormCae
                label={'Telefone'}
                type={'text'}
                width={'48%'}
                value={userInfos.phoneNumber}
                onChange={(e) => setUserInfos({ ...userInfos, phoneNumber: e.target.value })}
                placeholder={'Digite seu telefone'}
                />
              <FieldFormCae
                label={'WhatsApp'}
                type={'text'}
                width={'48%'}
                value={userInfos.whatsapp}
                onChange={(e) => setUserInfos({ ...userInfos, whatsapp: e.target.value })}
                placeholder={'Digite seu whatsapp'}
                />
              <FieldFormCae
                label={'País*'}
                type={'select'}
                values={getPlaces(Country.getAllCountries())}
                width={'28%'}
                value={userInfos.country}
                onChange={(e) => setUserInfos({ ...userInfos, country: e.target.value })}
                placeholder={'Selecione o país'}
              />
              <FieldFormCae
                label={'Estado*'}
                type={'select'}
                values={getPlaces(State.getStatesOfCountry(userInfos.country.slice(userInfos.country.length - 3, userInfos.country.length - 1)))}
                width={'32%'}
                value={userInfos.state}
                onChange={(e) => setUserInfos({ ...userInfos, state: e.target.value })}
                placeholder={'Selecione o estado'}
              />
              <FieldFormCae
                label={'Cidade*'}
                type={'select'}
                values={getCities(City.getCitiesOfState(userInfos.country.replace('(', ',').replace(')', ',').split(',')[1], userInfos.state.replace('(', ',').replace(')', ',').split(',')[1]))}
                width={'32%'}
                value={userInfos.city}
                onChange={(e) => setUserInfos({ ...userInfos, city: e.target.value })}
                placeholder={'Selecione o cidade'}
              />
            </div>
            { errorMessage !== '' ? <span className="error-message-form-cae second-error-message-form-cae">{errorMessage}</span> : null }
            <div className="div-buttons-cae-register">
              <button onClick={() => setStep(2)}>Voltar</button>
              <button onClick={() => checkFields(fieldsRequire, userInfos, passwordConfirmed, setErrorMessage, setStep)}>Próximo</button>
            </div>
          </div>
        </div>
        <FooterLoginCae />
      </div>
    );
  }

  if (step === 4) {
    return (
      <div className="CaeRegister">
        <div className="main-container-cae-register">
          <div className="header-container-cae-register">
            <span>Olá {userInfos.name}!</span>
            <span className="subspan-haeder-cae-register">Criando experiências</span>
          </div>
          <div className="div-title-cae-register">
            <h1>Cadastro da empresa/empreendedor</h1>
            <h6>Cadastro</h6>
          </div>
          <div className="div-body-cae-register">
            <p>Agora vamos para a etapa onde você irá cadastrar sua empresa ou agência que fornecerá uma experiência. Para isso abaixo temos um formulário com as informações a serem preenchidas.</p>
            <div className="line-break-cae-register" />
            <div className="div-form-cae-register">
              <span>Os campos com * são obrigatórios.</span>
              { errorMessage !== '' ? <span className="error-message-form-cae">{errorMessage}</span> : null }
              <FieldFormCae
                label={'Primeiro, conte para a gente o nome da sua empresa!*'}
                type={'text'}
                width={'100%'}
                value={agencyInfos.name}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, name: e.target.value })}
                placeholder={'Preencha com o nome da empresa'}
              />
              <FieldFormCae
                label={'E qual o nome do responsável? Ou nome da pessoa que está preenchendo o Caê*'}
                type={'text'}
                width={'100%'}
                value={agencyInfos.managerName}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, managerName: e.target.value })}
                placeholder={'Digite o nome do responsável'}
              />
              <FieldFormCae
                label={'Agora me passa o e-mail, por favor!*'}
                type={'text'}
                width={'100%'}
                value={agencyInfos.email}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, email: e.target.value })}
                placeholder={'Digite o email'}
              />
              <FieldFormCae
                label={'WhatsApp'}
                type={'textexplanation'}
                explanation={'Caso você não tenha WhatsApp, pode ser um telefone fixo. Preencher com o DDD, como o exemplo: (61) 99999-9999. Se o seu número for internacional, colocar o DDI, como o exemplo: +55 (XX) XXXXX-XXXX'}
                width={'100%'}
                value={agencyInfos.whatsapp}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, whatsapp: e.target.value })}
                placeholder={'Preencha com o whatsapp'}
              />
              <FieldFormCae
                label={'País*'}
                type={'select'}
                values={getPlaces(Country.getAllCountries())}
                width={'28%'}
                value={agencyInfos.country}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, country: e.target.value })}
                placeholder={'Selecione o país'}
              />
              <FieldFormCae
                label={'Estado*'}
                type={'select'}
                values={getPlaces(State.getStatesOfCountry(agencyInfos.country.slice(agencyInfos.country.length - 3, agencyInfos.country.length - 1)))}
                width={'32%'}
                value={agencyInfos.state}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, state: e.target.value })}
                placeholder={'Selecione o estado'}
              />
              <FieldFormCae
                label={'Cidade*'}
                values={getCities(City.getCitiesOfState(agencyInfos.country.replace('(', ',').replace(')', ',').split(',')[1], agencyInfos.state.replace('(', ',').replace(')', ',').split(',')[1]))}
                type={'select'}
                width={'32%'}
                value={agencyInfos.city}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, city: e.target.value })}
                placeholder={'Selecione a cidade'}
              />
              <FieldFormCae
                label={'Qual é o seu ramo de atuação?*'}
                type={'select'}
                values={['Agência de turismo receptiva', 'Agência de turismo emissiva', 'Agente independente', 'Artesão', 'Artista', 'Consultoria', 'Entretenimento, lazer e recreação', 'Gastronomia', 'Guia de turismo', 'Líder/Condutor de turismo de aventura', 'Meio de hospedagem (hotel, pousada, lodge, glamping, resort, etc.)', 'Operadora', 'Produtor rural', 'Terceiro setor', 'Transporte turístico (aéreo, terrestre e náutico)', 'Outro']}
                width={'100%'}
                value={agencyInfos.type}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, type: e.target.value })}
                placeholder={'Selecione o ramo de atuação'}
              />
              {
                agencyInfos.type === 'Outro' ? 
                <FieldFormCae
                  label={'Digite a outra opção*'}
                  type={'text'}
                  width={'100%'}
                  value={agencyInfos.typeOther}
                  onChange={(e) => setAgencyInfos({ ...agencyInfos, typeOther: e.target.value })}
                  placeholder={'Digite a outra opção'}
                /> : null
              }
              <FieldFormCae
                label={'Qual é o seu CNPJ?*'}
                type={'text'}
                width={'48%'}
                value={agencyInfos.cnpj}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, cnpj: e.target.value })}
                placeholder={'Preencha com o CNPJ'}
              />
              <FieldFormCae
                label={'Você possui Cadastur ativo?'}
                type={'radio'}
                width={'48%'}
                setAgencyInfos={setAgencyInfos}
                agencyInfos={agencyInfos}
                field={'CadasturAtivo'}
              />
              <FieldFormCae
                label={'Tem site oficial ou principal? Se sim, insira o(s) link(s)'}
                type={'text'}
                width={'100%'}
                value={agencyInfos.site}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, site: e.target.value })}
                placeholder={'Preencha com o link'}
              />
              <FieldFormCae
                label={'Insira o link de Instagram'}
                type={'text'}
                width={'100%'}
                value={agencyInfos.instagram}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, instagram: e.target.value })}
                placeholder={'Preencha com o link'}
              />
              <FieldFormCae
                label={'Insira o link de Facebook'}
                type={'text'}
                width={'100%'}
                value={agencyInfos.facebook}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, facebook: e.target.value })}
                placeholder={'Preencha com o link'}
              />
              <FieldFormCae
                label={'Insira o link de YouTube'}
                type={'text'}
                width={'100%'}
                value={agencyInfos.youtube}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, youtube: e.target.value })}
                placeholder={'Preencha com o link'}
              />
              <FieldFormCae
                label={'Insira o link de TikTok'}
                type={'text'}
                width={'100%'}
                value={agencyInfos.tiktok}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, tiktok: e.target.value })}
                placeholder={'Preencha com o link'}
              />
              <FieldFormCae
                label={'Insira outros links que existirem'}
                type={'textarea'}
                width={'100%'}
                value={agencyInfos.otherLinks}
                onChange={(e) => setAgencyInfos({ ...agencyInfos, otherLinks: e.target.value })}
                placeholder={'Preencha com os links'}
              />
            </div>
            { errorMessage !== '' ? <span className="error-message-form-cae second-error-message-form-cae">{errorMessage}</span> : null }
            <div className="div-buttons-cae-register">
              <button onClick={() => setStep(3)}>Voltar</button>
              <button onClick={() => checkAgencyFields(fieldsAgencyRequire, agencyInfos, userInfos, createUserAndAgency, setErrorMessage, handleSetData)}>Próximo</button>
            </div>
          </div>
        </div>
        <FooterLoginCae />
      </div>
    );
  }
}