function containsSearch(data, search, fields) {
  const lowerSearch = search.toLowerCase();

  for (let field = 0; field < fields.length; field++)
    if (data[fields[field]] && data[fields[field]].toLowerCase().includes(lowerSearch))
      return true;

  return false;
}

export function handleFilterDataBySearch(setFilteredData, data, fields, search, typeDoc) {
  const filteredData = data.filter((da) => {
    if (search === '' || containsSearch(typeDoc ? da.data() : da, search, fields))
      return true;

    return false;
  });

  setFilteredData(filteredData);
}