import React from 'react';
import SideBarAdmin from '../../component/SidebarAdmin';
import '../../assets/css/indexAdmin.css';

export default function IndexAdmin() {
  return (
    <div className='AppAdmin'>
      <SideBarAdmin />
    </div>
  );
}
