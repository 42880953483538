import React, { useState, useEffect, useContext } from 'react';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';

// Assets imports
import SearchIcon from '../../assets/imgs/search_icon.svg';

// Components imports
import { RodapeAdmin } from '../../component/RodapeAdmin';
import CorrectionsList from '../../component/CorrectionsList';
import EditEvents from '../../component/EditComponents/EditEvents';
import EditInventory from '../../component/EditComponents/EditInventory';
import Loading from '../../component/Loading';
import InputCheckboxCustom from '../../component/InputComponents/InputCheckBoxCustom';
import NoResultsComponent from '../../component/NoResultsComponent';

// Firebase import
import firebase from '../../config/Firebase';

export default function CorrectionsAdmin() {
  const [filter, setFilter] = useState('feedback');
  const [editPopup, setEditPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [search, setSearch] = useState('');
  const [places, setPlaces] = useState();
  const [typeDoc, setTypeDoc] = useState();
  const [action, setAction] = useState('Editar');
  const [filteredPlaces, setFilteredPlaces] = useState();
  const { city, role, cityAdmin, setNumCorrections, numCorrections } = useContext(MyContext);
  const editType = {
    Evento: <EditEvents doc={selectedItem} setEditPopup={setEditPopup} action={action} userCity={city} />,
    Inventario: <EditInventory doc={selectedItem} setEditPopup={setEditPopup} action={action} userCity={city} role={role} cityAdmin={cityAdmin} />,
    Atrativo: <EditInventory doc={selectedItem} setEditPopup={setEditPopup} action={action} userCity={city} role={role} cityAdmin={cityAdmin} />,
  };
  const [listCorr, setListCorr] = useState({
    ids: [],
    values: [],
  });

  const buildQuery = () => {
    const currFilter = filter === 'searchBar' ? 'feedback' : filter;

    let docs;

    if (role !== 'cityAdmin')
      docs = firebase.firestore()
        .collection('Feedbacks')
        .orderBy(currFilter);
    else
      docs = firebase.firestore()
        .collection('Feedbacks')
        .where('city', 'in', cityAdmin)
        .orderBy(currFilter);
        
    return docs;
  };

  const getFirstPage = async () => {
    const newDocs = await buildQuery().get();

    setPlaces(newDocs);
    setFilteredPlaces(newDocs);
  };

  useEffect(() => {
    if (!editPopup) 
      setSelectedItem(undefined);
    if (city)
      getFirstPage();
  }, [city, filter, editPopup, numCorrections]);

  useEffect(() => {
    setListCorr({
      ids: [],
      values: [],
    });
  }, [editPopup, numCorrections]);

  useEffect(() => {
    handleShowButtonsPend();
  }, [listCorr]);

  const handleEdit = async (doc) => {
    const collect = {
      Evento: 'Events',
      Inventario: 'Attractions',
      Atrativo: 'Attractions',
    };

    const docEdit = await firebase.firestore().collection(collect[doc.data().tipo]).doc(doc.data().ID).get();
    
    setSelectedItem(docEdit);
    setTypeDoc(doc.data().tipo);
    setAction('Editar');
    setEditPopup(true);
  };

  const handleDeleteCorr = async (doc) => {
    await firebase.firestore().collection('Feedbacks').doc(doc.id).delete();

    setNumCorrections((num) => num - 1);
    getFirstPage();
  };

  async function handleDeleteLoteCorr() {
    listCorr.values.map(async (docCorr) => {
      await handleDeleteCorr(docCorr);
    });

    setListCorr({
      ids: [],
      values: [],
    });
  }

  const searchInventoryText = (doc) => {
    if (search === '')
      return true;
    
    const isInTitle = doc.data().titulo.toLowerCase().includes(search.toLowerCase());
    return isInTitle;
  };

  const handleSearch = () => {
    if (places.docs) {
      let filteredPlaces = places.docs.filter((doc) => searchInventoryText(doc));
      filteredPlaces = { docs: filteredPlaces };
      setFilteredPlaces(filteredPlaces);
    }
  };

  function handleAddDocList(doc) {
    const arrayId = listCorr.ids;
    const arrayVal = listCorr.values;

    const index = arrayId.indexOf(doc.id);
    if (index !== -1) {
      arrayId.splice(index, 1);
      arrayVal.splice(index, 1);
    } else {
      arrayId.push(doc.id);
      arrayVal.push(doc);
    }

    setListCorr({
      ids: arrayId,
      values: arrayVal,
    });
  }

  function handleSetCorrections(e) {
    const isChecked = document.getElementById('check_all_pendencies').checked;
    const fields = document.getElementsByName('checkbox_corrections');

    for (let i = 0; i < fields.length; i++) {
      if (isChecked && !fields[i].checked)
        fields[i].click();
      else if (!isChecked && fields[i].checked)
        fields[i].click();
    }
  }

  function handleShowButtonsPend() {
    if (!document.getElementById('btn_approve_pendency')) return;

    const isChecked = listCorr.values.length > 0;

    if (!isChecked)
      document.getElementById('check_all_pendencies').checked = isChecked;

    document.getElementById('div_review_manyPendencies').style = `opacity: ${isChecked ? 1 : 0};`;
    document.getElementById('div_select_all_pendencies').style = `opacity: ${isChecked ? 1 : 0};`;
  }

  if (!places || !city) {
    return <Loading />;
  }

  return (
    <div className='InventarioAdmin'>
      { editPopup && selectedItem && typeDoc ? editType[typeDoc] : (
        <div className='body_inventory_adm'>
          <div className='div_title_inventory_adm'>
            <h1>Correções</h1>
          </div>
          <div className='div_align_pendencies'>
            <div className='div_browse_inventory_adm'>
              <input type='text' className='input_browse_inventory' onChange={(e) => { setSearch(e.target.value); handleSearch(); }} placeholder='Buscar' />
              <button type="button" className='btn_browse_inventory_adm' disabled>
                <img src={SearchIcon} alt='Icone de pesquisa' className='search_icon_admin'/>
              </button>
            </div>
            <div className='div_review_manyPendencies' id='div_review_manyPendencies'>
                <p>{listCorr.ids.length} Correções Selecionadas</p>
                <button type='button' id='btn_approve_pendency' disabled={listCorr.values.length === 0} onClick={() => handleDeleteLoteCorr() }>Concluir</button>
            </div>
          </div>
          { filteredPlaces && filteredPlaces.docs.length > 0 ? (
          <div className='div_table_inventory_adm' id='div_table_inventory_adm'>
            <table className='table_inventory_adm'>
              <thead>
                <tr className="row_table_inventory th_table_admin">
                  <td className='column_check_table_inventory th_table_admin'>
                    <div className='div_select_all_pendencies' id='div_select_all_pendencies'>
                      <label htmlFor='check_all_pendencies' className='label_check_all_pendencies'>Todas</label>
                      <InputCheckboxCustom handleAction={handleSetCorrections} listPend={listCorr} nameCheck={''} />
                    </div>
                  </td>
                  <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('titulo')}>Título</td>
                  <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('data')}>Data</td>
                  <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('feedback')} >Feedback</td>
                  <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('ID')}>Local</td>
                  <td className='column_table_inventory th_table_admin'></td>
                </tr>
              </thead>
              <tbody>
                {
                  filteredPlaces.docs.map((doc) => {
                    return <CorrectionsList doc={doc} key={doc.id} handleEdit={handleEdit} handleDelete={handleDeleteCorr} handleAddDocList={handleAddDocList} />;
                  })
                }
              </tbody>
            </table>
          </div>) : <NoResultsComponent title={'Correções'} search={search} /> }
        </div>
      )}
    </div>
  );
}