import React from "react";

import '../../assets/css/CaeLogin/fieldFormCae.css';

export default function FieldFormCae({ label, values, value, explanation, type, width, alt, setAgencyInfos, agencyInfos, field, ...props }) {
  if (type === 'text' || type === 'password') {
    return (
      <div className="FieldFormCae" style={{ width: width }}>
        <label id={label + '_form'}>{label}</label>
        <input id={label + '_form'} type={type} value={value || ''} {...props} />
      </div>
    );
  }

  if (type === 'select') {
    return (
      <div className="FieldFormCae" style={{ width: width }}>
        <label htmlFor={label + '_form'}>{label}</label>
        <select {...props} id={label + '_form'} defaultValue={value || ''}>
        <option value="">{props.placeholder}</option>;
          {
            values.map((val, index) => {
              return <option key={val + index}>{val}</option>;
            })
          }
        </select>
      </div>
    );
  }
  if (type === 'textarea') {
    return (
      <div className="FieldFormCae" style={{ width: width }}>
        <label htmlFor={label + '_form'}>{label}</label>
        <textarea id={label + '_form'} type={type} value={value || ''} {...props} />
      </div>
    );
  }

  if (type === 'textexplanation') {
    return (
      <div className="FieldFormCae FieldFormCaeExplanation" style={{ width: width }}>
        <label id={label + '_form'}>{label}</label>
        <span>{explanation}</span>
        <input id={label + '_form'} type={type} value={value || ''} {...props} />
      </div>
    );
  }

  if (type === 'radio') {
    return (
      <div className="FieldFormCae" style={{ width: width }}>
        <label>{label}</label>
        <div className="div-radio-cae-form">
          <div className="div-align-radio-cae-form">
            <label className='radio-form-cae'>
              <input type='radio' name="cadastur" id="have-cadastur" {...props} onChange={() => setAgencyInfos({ ...agencyInfos, [field]: true })} />
              <div className='check-icon-radio-form-cae' /> 
            </label>
            <label htmlFor="have-cadastur" className="label-radio-form-cae">Sim</label>
          </div>
          <div className="div-align-radio-cae-form">
            <label className='radio-form-cae'>
              <input type='radio' name="cadastur" id="dont-have-cadastur" {...props} onChange={() => setAgencyInfos({ ...agencyInfos, [field]: false })} />
              <div className='check-icon-radio-form-cae' />
            </label>
            <label htmlFor="dont-have-cadastur" className="label-radio-form-cae">Não</label>
          </div>
        </div>
      </div>
    );
  }
}