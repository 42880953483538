import React from 'react';
import firebase from '../config/Firebase';
import { StyleSheet, css } from 'aphrodite';
import Logo from '../assets/imgs/Destinos.png';
import bg from '../assets/imgs/desk_bg.png';

import {
  Button, Form, FormGroup, Input,
} from 'reactstrap';
import { Redirect, withRouter } from 'react-router-dom';
import AlertMessage from '../component/Alert';
import SingleSelect from '../component/CitiesSelector';
import { PageView } from '../config/TrackingGA';

// import { NonceProvider } from 'react-select';

class SignUp extends React.Component {
  componentDidMount() {
    PageView();
  }

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      fullName: '',
      city: '',
      password: '',
      errorMsg: '',
      errorFlag: false,
      warningMsg: '',
      warningFlag: false,
      toMap: false,
      loading: true,
    };
  }

  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        if (cname === 'hasLogin' && c.includes('false')) return false;
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }

  componentWillMount() {
    this.checkAuthentication();
    PageView();
  }

  checkAuthentication() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) this.props.history.push('/map');
      // Edimar - Alterado pois na navegação anonima, ao clicar no icone do perfil ele saía para a tela de login, ao clicar em CRIAR conta ele voltava para a mesma tela
      // else if (this.getCookie('hasLogin')) this.props.history.push('/login');
      else this.setState({ loading: false });
    });
  }

  onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.handleSignUp();
    }
  }

  handleFacebookCallback = (result) => {
    if (result === 'incomplete') {
      this.setState({ toSignUpWithSocialMedia: true, provider: 'Facebook' });
    } else if (result === 'complete') {
      this.setState({ toMap: true });
    } else {
      this.setState({ errorFlag: true, errorMsg: result });
    }
  }

  handleGoogleCallback = (result) => {
    if (result === true) {
      this.setState({ toSignUpWithSocialMedia: true, provider: 'Google' });
    } else {
      this.setState({ errorFlag: true, errorMsg: 'Ocorreu um erro' });
    }
  }

  handleSignUp = (event) => {
    const { email, password, city, fullName } = this.state;

    this.setState({ errorMsg: '', errorFlag: false });
    event.preventDefault();

    if (email === '') this.setState({ errorMsg: 'Digite seu email', errorFlag: true });
    else if (fullName === '') this.setState({ errorMsg: 'Digite seu nome completo ', errorFlag: true });
    else if (city === '') this.setState({ errorMsg: 'Digite sua cidade', errorFlag: true });
    else if (password === '') this.setState({ errorMsg: 'Digite seu senha', errorFlag: true });
    else {
      firebase.auth().createUserWithEmailAndPassword(email, password).then(async () => {
        /* Se chegar aqui, cadastro feito */
        const userId = firebase.auth().currentUser.uid;

        firebase.auth().currentUser.sendEmailVerification();

        firebase.auth().currentUser.updateProfile({
          displayName: fullName,
        });

        let user = {
          name: fullName,
          email: email,
          city: city,
          image: '',
        };

        const docsAttr = await firebase.firestore().collection("Attractions").where("emailAdmin", "==", email).get();
      
        if (docsAttr.docs.length > 0) {
          const fetchAttractions = docsAttr.docs.map((doc) => doc.id);
          user = { ...user, role: "tradeAdmin", tradeAdmin: fetchAttractions };
        }

        firebase.firestore().collection('users').doc(userId).set(user);
        this.setState({
          email: '',
          fullName: '',
          city: '',
          password: '',
          errorMsg: '',
          errorFlag: false,
          warningFlag: false,
          warningMsg: '',
        });
        this.props.history.push(docsAttr.docs.length > 0 ? '/admin' : '/map');
      }).catch((error) => {
        if (error.code === 'auth/invalid-email') this.setState({ errorMsg: 'Email inválido', errorFlag: true });

        else if (error.code === 'auth/email-already-exists	') this.setState({ errorMsg: 'Email já cadastrado', errorFlag: true });

        else if (error.code === 'auth/weak-password') this.setState({ errorMsg: 'Senha fraca', errorFlag: true });

        else if (error.code === 'auth/email-already-in-use') this.setState({ errorMsg: 'Email já cadastrado', errorFlag: true });

        else {
          alert('Ocorreu um erro');
          console.error(`Erro: \n ${error.code}`);
        }
      });
    }
  }

  handleChange = (name) => (event) => {
    if (name === 'city') {
      if (event === null)
        this.setState({ [name]: '' });
      else
        this.setState({ [name]: event.value });
    } else {
      this.setState({ [name]: event.target.value });
    }
  }

  forms = () => {
    return (
      <Form style={{ textAlign: 'center' }}>
        <FormGroup className={css(styles.formGroup)}>
          <Input
            onClick={(e) => { e.target.focus(); }}
            style={{ width: '320px', height: 'rem', color: 'grey' }}
            type='email'
            name='email'
            placeholder='Email'
            value={this.state.email}
            onChange={this.handleChange('email')}
          />
        </FormGroup>

        <FormGroup className={css(styles.formGroup)}>
          <Input
            style={{ width: '320px', height: 'rem', color: 'grey' }}
            onClick={(e) => { e.target.focus(); }}
            type='text'
            name='fullName'
            placeholder='Nome completo'
            value={this.state.fullName}
            onChange={this.handleChange('fullName')}
          />
        </FormGroup>

        <FormGroup className={css(styles.formGroup)}>
          <SingleSelect
            className={css(styles.citySelect)}
            style={{ width: '320px', height: 'rem', color: 'grey' }}
            placeholder={'Cidades'}
            value={this.state.city}
            onChange={this.handleChange('city')}
          />
        </FormGroup>

        <FormGroup className={css(styles.formGroup)}>
          <Input
            onClick={(e) => { e.target.focus(); }}
            style={{ width: '320px', height: 'rem', color: 'grey' }}
            type='password'
            name='password'
            placeholder='Senha'
            value={this.state.password}
            onChange={this.handleChange('password')}
          />
        </FormGroup>
        <Button className={css(styles.formButton)} onClick={this.handleSignUp}>Cadastrar-se</Button>
      </Form>
    );
  }

  render() {
    if (this.state.loading) return <p>Loading...</p>;

    if (this.state.toMap) {
      return <Redirect to='/map' />;
    }

    if (this.state.toSignUpWithSocialMedia) {
      return (<Redirect to={{ path: '/signup-with-social-media', state: { provider: this.state.provider } }} />);
    }

    return (
      <div className={css(styles.pageBackground)}>
        <div className={css(styles.container, styles.collumn)} style={{ overflowY: window.innerWidth <= 500 ? 'scroll' : 'none' }}>
          <img alt='' src={Logo} style={{ marginTop: '5%', height: '17vh' }} />

          {this.state.errorFlag && <AlertMessage color='danger' isOpen={this.state.errorFlag} onToggle={() => { this.setState({ errorFlag: false }); }} msg={this.state.errorMsg} />}
          {this.state.warningFlag && <AlertMessage color='warning' isOpen={this.state.warningFlag} onToggle={() => { this.setState({ warningFlag: false }); }} msg={this.state.warningMsg} />}
          {this.forms()}

          <div className={css(styles.termos)}>
            <p><span style={{ fontSize: 14 }}>
              Ao se cadastrar, você concorda com nossos
              <button
              type="button"
              className="link-button"
              onClick={() => { window.open('http://www.destinosinteligentes.tur.br/legal/termos.html', 'newwindow', 'width=500, height=350, top=100, left=100'); }}
              style={{ color: 'white', cursor: 'pointer', backgroundColor: 'transparent', border: 'none', display: 'inline', margin: 0, padding: 0 }}>

               <strong>Termos de uso &nbsp;</strong>
              </button>
              e nossa &nbsp;
              <button
              type="button"
              className="link-button"
              onClick={() => { window.open('http://www.destinosinteligentes.tur.br/legal/privacidade.html', 'newwindow', 'width=500, height=350, top=100, left=100'); }}
              style={{ color: 'white', cursor: 'pointer', backgroundColor: 'transparent', border: 'none', display: 'inline', margin: 0, padding: 0 }}>

             <strong>Política de privacidade</strong></button>

            </span>
            </p>
          </div>

          <div className={css(styles.login, styles.collumn)}>
            <span>
              Tem uma &nbsp;
              <button
              type="button"
              className="link-button"
              onClick={() => this.props.history.push('/login')}
              style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline', backgroundColor: 'transparent', border: 'none', display: 'inline', margin: 0, padding: 0 }}>
                conta?
                </button>

            </span>
          </div>

        </div>
      </div>
    );
  }
}
export default withRouter(SignUp);

const styles = StyleSheet.create({
  citySelect: {
    color: 'grey',
  },
  container: {
    maxWidth: '400px',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  errorMsg: {
    fontStyle: 'italic bold',
  },
  collumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  formButton: {
    width: '100%',
    backgroundColor: ' #3399ff',
    ':hover': {
      backgroundColor: '#3c8ed7',
    },
  },
  formGroup: {
    marginBottom: '0.2rem',
  },
  line: {
    overflow: 'hidden',
    marginTop: '.8rem',
    width: '48%',
    color: 'white',
    backgroundColor: 'white',
  },
  login: {
    fontSize: '1rem',
    textAlign: 'center',
    marginTop: 'auto',
  },
  ouText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  pageBackground: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    minHeight: '100%',
    maxHeight: '100%',
    overflowY: 'hidden',
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    maxWidth: '320px',
    marginTop: '0.7rem',
    marginBottom: '0.7rem',
  },
  subtitleText: {
    textAlign: 'center',
    maxWidth: '250px',
    marginBottom: '1rem',

  },
  termos: {
    marginTop: '5px',
    textAlign: 'center',
    marginLeft: '50px',
    marginRight: '50px',
    fontSize: '12px',
  },
});
