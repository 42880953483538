import React from "react";

import '../../assets/css/footer.css';

import InstagramIcon from '../../assets/imgs/instagram_icon.svg';
import LinkedinIcon from '../../assets/imgs/linkedin_icon.svg';

export default function Footer() {
  return (
    <div className="Footer">
      <div>
        <span>Destinos Inteligentes TI Ltda - Todos os direitos reservados.</span>
      </div>
      <div>
        <a href="https://www.instagram.com/destinosinteligentesoficial/" target="_blank">
					<img src={InstagramIcon} alt="Ícone do Instagram" className="icon-footer" />
				</a>
				<a href="https://www.linkedin.com/company/destinosinteligentes/" target="_blank">
					<img src={LinkedinIcon} alt="Ícone do Linkedin" className="icon-footer" />
				</a>
      </div>
    </div>
  );
}