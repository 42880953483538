import React, { useContext, useEffect, useState } from 'react';
import '../assets/css/headerAdmin.css';
import firebase from '../config/Firebase';
import { useHistory } from 'react-router-dom';
import IconLogout from '../assets/imgs/logout_icon.svg';

// Components Imports
import Loading from './Loading';

// eslint-disable-next-line import/no-cycle
import { MyContext } from './SidebarAdmin';
import { sortByName } from '../utils/limitStr';

export default function HeaderAdmin({ statusNavbar, setStatusNavbar }) {
  const history = useHistory();
  const [cities, setCities] = useState();
  const { city, setCity, role, cityAdmin, setNeighborhood } = useContext(MyContext);

  const handleNavbar = () => {
    if (statusNavbar === true) {
      document.getElementById('sidebarName').style.display = 'none';
      document.getElementById('sidebarHeader').style = 'flex-direction: row;';
      document.getElementById('sideBar').style = 'width: 5%';
      document.getElementById('div_align_sidebar').style = 'width: 5%';
      document.getElementById('SideBarBody').style = 'width: 95%';

      const titles = document.getElementsByClassName('titleSidebar');
      for (let i = 0; i < titles.length; i++) {
        titles[i].style = 'opacity: 0';
      }

      const icons = document.getElementsByClassName('iconSidebar');
      for (let i = 0; i < icons.length; i++) {
        icons[i].style = 'position: relative; margin-left: 1.3rem';
      }

      setStatusNavbar(false);
    } else {
      document.getElementById('sidebarName').style.display = 'grid';
      document.getElementById('sidebarHeader').style = 'flex-direction: column;';
      document.getElementById('sideBar').style = 'width: 18%';
      document.getElementById('div_align_sidebar').style = 'width: 18%';
      document.getElementById('SideBarBody').style = 'width: 82%';

      const titles = document.getElementsByClassName('titleSidebar');
      for (let i = 0; i < titles.length; i++) {
        titles[i].style = 'animation: ease-item-delay 0.5s linear;';
      }

      const icons = document.getElementsByClassName('iconSidebar');
      for (let i = 0; i < icons.length; i++) {
        icons[i].style = 'position: relative; margin-left:0';
      }

      setStatusNavbar(true);
    }
  };

  const signoutAdm = () => {
    localStorage.removeItem('userInfos');
    localStorage.removeItem('uid');
    setTimeout(() => {
      firebase.auth().signOut().then(() => {
        history.push('/adm');
      });
    }, 500);
  };

  const SandwichButtom = () => {
    return (
      <div onClick={() => handleNavbar()} className='btn_header_adm'>
        <div className='strip_btn_header_adm' />
        <div className='strip_btn_header_adm' />
        <div className='strip_btn_header_adm' />
      </div>
    );
  };

  useEffect(() => {
    async function handleInfoCity() {
      const userData = await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).get();
      if (role !== 'cityAdmin')
        setCity(userData.data().city);
      else {
        const citiesUser = userData.data().cityAdmin;
        setCity(citiesUser.includes(userData.data().city) ? userData.data().city : citiesUser[0]);
      }
    }

    async function handleCities() {
      if (!role) return;
      
      let names = [];
  
      if (role !== 'cityAdmin' && role !== 'dashboardViewer') {
        const docs = await firebase.firestore().collection('Cities').orderBy('nameOrdem').get();

        names = docs.docs.map((doc) => doc.data().name)
      } else if (cityAdmin) {
        names = cityAdmin.sort(sortByName);
      }
  
      setCities(names);
    }

    handleInfoCity();
    handleCities();
  }, [role, cityAdmin, setCity]);

  async function handleNeighborhood() {
    if (!city) return;

    setNeighborhood();

    const doc = await firebase.firestore().collection('Cities')
      .where('name', '==', city)
      .get();
    
    if (doc.docs[0].data().regiao) {
      setNeighborhood(doc.docs[0].data().neighborhood);
    } else {
      setNeighborhood([]);
    }

  }

  useEffect(() => {
    handleNeighborhood();
  }, [city]);

  if (!cities || !city || !role) {
    return <Loading />;
  }

  return (
      <div className='body_header_admin'>
        <div className='div_btn_sidebar'>
          <SandwichButtom/>
          <div className='div_title_header'>
            DASHBOARD GERAL
          </div>
        </div>
        <div className='div_logout_adm'>
          <div className='div_select_city_header'>
            <label htmlFor='select_city_header'>Cidade:</label>
            <select id='select_city_header' value={city} style={{ width: '150px' }} onChange={(e) => setCity(e.target.value)}>
                {
                  cities.map((name) => {
                    return <option key={name} disabled={['tradeAdmin'].includes(role)} >{name}</option>;
                  })
                }
            </select>
          </div>
          <button type='button' className='btn_logout_adm' onClick={() => signoutAdm()}>
            <img src={IconLogout} className="icon_logout_header" alt="Icone de senha" />
            Log Out
          </button>
        </div>
      </div>
  );
}
