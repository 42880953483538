export const limitStr = (str) => {
  if (str) {
    if (str.length > 200)
      return str.slice(0, 200) + '...';
    return str;
  }
  return 'Não Há';
};

export const limitTitle = (str) => {
  if (str.length > 200)
    return str.slice(0, 50) + '...';
  return str;
}

export const limitTextNum = (str, num) => {
  if (str) {
    if (str.length > num)
      return str.slice(0, num) + '...';
    return str;
  }
  return 'Não Há';
}

export const endStr = (str) => {
  if (str) {
    const size = str.length;
    return str.slice(0, size - 3) + '.';
  }
  return 'Não Há';
};

export const generatorRandowString = () => {
  const caracters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let text = '';

  for (let i = 0; i < 30; i++) {
    text += caracters.charAt(Math.floor(Math.random() * 62));
  }

  return text;
};

export const handleStringFormat = (string) => {
  return string
    .replaceAll('Á', 'A')
    .replaceAll('É', 'E')
    .replaceAll('Í', 'I')
    .replaceAll('Ó', 'O')
    .replaceAll('Ú', 'U')
    .replaceAll('Ã', 'A')
    .replaceAll('Õ', 'O')
    .replaceAll('Â', 'A')
    .replaceAll('Ê', 'E')
    .replaceAll('Ô', 'O')
    .replaceAll('Ç', 'C')
    .replaceAll('á', 'a')
    .replaceAll('é', 'e')
    .replaceAll('í', 'i')
    .replaceAll('ó', 'o')
    .replaceAll('ú', 'u')
    .replaceAll('ã', 'a')
    .replaceAll('õ', 'o')
    .replaceAll('â', 'a')
    .replaceAll('ê', 'e')
    .replaceAll('ô', 'o')
    .replaceAll('ç', 'c');
};

export const sortByName = (a, b) => {
  const fillA = handleStringFormat(a);
  const fillB = handleStringFormat(b);

  if (fillA > fillB)
    return 1;
  else if (fillA < fillB)
    return -1;

  return 0;
}