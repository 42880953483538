import React from "react";

export function TextareaField({ title, fieldForm, placeholder, explanation, value, item, setItem, disabled, limit, ...props }) {
  return (
    <div className="field-textarea-edit" {...props}>
      <label htmlFor={fieldForm}>{title}</label>
      { explanation }
      <textarea className="field-edit" id={fieldForm} placeholder={placeholder} value={value} onChange={(e) => setItem({ ...item, [fieldForm]: e.target.value })} disabled={disabled} maxLength={limit || undefined} />
    </div>
  );
}
