import React from 'react';

export function MultipleRadioField({ title, values, fieldForm, setValue, value, disabled, ...props }) {
  return (
    <div className="field-radio-edit" {...props}>
      <label className="title-radio-edit">{title}</label>
      <div className="div-align-radio-multiple-field">
        <div className="row-radio-multiple">
          <div className="column-radio-multiple" />
          <div className="subcolumn-radio-multiple">
            <span>Não atende</span>
          </div>
          <div className="subcolumn-radio-multiple">
            <span>Atende parcialmente</span>
          </div>
          <div className="subcolumn-radio-multiple" >
            <span>Atende totalmente</span>
          </div>
        </div>
        { values.map((field, index) => {
          return (
            <div className="subrow-radio-multiple" key={fieldForm + field + index}>
              <div className="column-radio-multiple">
                { field }
              </div>
              <div className="subcolumn-radio-multiple" >
                <label className='radio-edit'>
                  <input type='radio' name={field} onChange={() => setValue({ ...value, [fieldForm]: { ...value[fieldForm], [field]: 'Não atende' } })} checked={value[fieldForm] ? value[fieldForm][field] === 'Não atende' : false} disabled={disabled} />
                  <div className='radio-input'>
                    <div className='check-radio-input'/>
                  </div>
                </label>
              </div>
              <div className="subcolumn-radio-multiple" >
                <label className='radio-edit'>
                  <input type='radio' name={field} onChange={() => setValue({ ...value, [fieldForm]: { ...value[fieldForm], [field]: 'Atende parcialmente' } })} checked={value[fieldForm] ? value[fieldForm][field] === 'Atende parcialmente' : false} disabled={disabled} />
                  <div className='radio-input'>
                    <div className='check-radio-input'/>
                  </div>
                </label>
              </div>
              <div className="subcolumn-radio-multiple" >
                <label className='radio-edit'>
                  <input type='radio' name={field} onChange={() => setValue({ ...value, [fieldForm]: { ...value[fieldForm], [field]: 'Atende totalmente' } })} checked={value[fieldForm] ? value[fieldForm][field] === 'Atende totalmente' : false} disabled={disabled} />
                  <div className='radio-input'>
                    <div className='check-radio-input'/>
                  </div>
                </label>
              </div>
            </div>
          );
        }) }
      </div>
    </div>
  )
}