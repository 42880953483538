import React from "react";

export function CheckboxField({ title, values, styleSub, fieldForm, setValue, value, explanation, limit, disabled, ...props }) {
  function handleValues(val) {
    const set = value[fieldForm] || {};

    if (set[val] === undefined) {
      if (val === 'Outro')
        return { ...set, [val]: '' };

      return { ...set, [val]: true };
    }
    
    delete set[val];
    return set;
  }

  return (
    <div className="field-checkbox-edit" {...props}>
      <label className="title-checkbox-edit">{title}</label>
      { explanation }
      <div className="div-align-checkbox-field">
        {
          values.map((field, index) => {
            return (
              <div className="div-checkbox-edit" style={styleSub} key={title + field + index}>
                <label className={'checkbox-edit' + (disabled ? ' disabled-checkmark' : '')}>
                  <input type='checkbox' name={fieldForm} id={fieldForm + field + index} checked={value[fieldForm] ? value[fieldForm][field] !== undefined : false} onChange={() => setValue({ ...value, [fieldForm]: handleValues(field) })} disabled={disabled || ((limit && value[fieldForm]) && !value[fieldForm][field] ? Object.keys(value[fieldForm]).length === limit : false)} />
                  <div className='checkbox-input'>
                    <div className='check-checkbox-input'/>
                  </div>
                </label>
                { field === 'Outro' && value[fieldForm] && value[fieldForm][field] !== undefined ? <input className="other-option-input-edit" type="text" onChange={(e) => setValue({ ...value, [fieldForm]: { ...value[fieldForm], [field]: e.target.value } })} value={value[fieldForm][field]} disabled={disabled} /> : <label className="label-checkbox-edit" htmlFor={fieldForm + field + index} >{field}</label>}
              </div>
            );
          })
        }
      </div>
    </div>
  );
}