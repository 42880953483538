import React, { useState } from "react";

import "../assets/css/resizeImage.css";

import AvatarEditor from 'react-avatar-editor'

export default function ResizeImage({ imagePath, setCropImage, setFinalImage }) {
  const [zoom, setZoom] = useState(1.5);
  const [editorObj, setEditorObj] = useState();
  
  function handleCropImage() {
    const imageObj = editorObj.getImageScaledToCanvas();
    imageObj.toBlob((blob) => {
      const file = new File([blob], "userPhotoProfile.jpg", { type: "image/jpeg" });

      setFinalImage({ file: file, path: imageObj.toDataURL() });
    }, 'image/jpeg');

    setCropImage(false);
  }

  const setEditorRef = (editor) => {
    if (editor)
      setEditorObj(editor);
  };
  
  return (
    <div className="ResizeImage">
      <div className="inner-popup-resize-image">
        <div className="div-align-resize-component">
          <AvatarEditor
            image={imagePath}
            width={140}
            height={140}
            border={50}
            color={[255, 255, 255, 0.2]}
            scale={zoom}
            rotate={0}
            style={{ backgroundColor: "rgba(3, 24, 43, 0.85)" }}
            ref={setEditorRef}
          />
        </div>
        <div className="div-align-input-resize-image">
          <input type="range" defaultValue={zoom} max={2} min={1} step={0.01} onChange={(e) => setZoom(Number(e.target.value))} />
        </div>
        <div className="div-align-buttons-resize-image">
          <button onClick={() => setCropImage(false)}>Cancelar</button>
          <button onClick={() => handleCropImage()}>Confirmar</button>
        </div>
      </div>
    </div>
  )
}