import React, { useEffect, useState } from "react";

// React-router-dom import
import { Route, Switch, useHistory } from 'react-router-dom';

import IconDI from '../../assets/imgs/default.png';
import OptionsIcon from '../../assets/imgs/options_icon.svg';
import UserIcon from '../../assets/imgs/user_icon_navbar.svg';
import ArrowIcon from '../../assets/imgs/arrow_navbar.svg';
import ConfigIcon from '../../assets/imgs/config_icon_header.svg';
import LogoutIcon from '../../assets/imgs/logout_icon_header.svg';
import LogoDestinosInteligentes from '../../assets/imgs/footer_logo_destinos.png';
import LogoBrasilCae from '../../assets/imgs/logo_brasil_cae.png';

import firebase from '../../config/Firebase';

import '../../assets/css/cae.css';

import { usersPermissions } from '../../utils/cae/usersPermissions';

import Experiences from './Experiences';
import Agencys from './Agencys';
import { navbarItems } from '../../component/Cae/NavbarItems';
import Footer from './Footer';
import ProfileCae from "./ProfileCae";
import { getUser } from "../../apis/cae.endpoint";

export const MyContext = React.createContext();

export default function Cae() {
  const [userInfos, setUserInfos] = useState(JSON.parse(localStorage.getItem('userInfos')));
  const [uid, setUid] = useState(localStorage.getItem('uid'));
  const [optionsPopupIsOpen, setOptionsPopupIsOpen] = useState(false);
  const [navbarIsOpen, setNavnarIsOpen] = useState(true);
  const history = useHistory();

  function openOptionsPopup() {
    const popup = document.getElementById('options-popup');

    if (optionsPopupIsOpen)
      popup.style = 'display: none';
    else
      popup.style = 'display: flex';

    setOptionsPopupIsOpen(!optionsPopupIsOpen);
  }

  function logout() {
    localStorage.removeItem('userInfos');
    localStorage.removeItem('uid');
    localStorage.removeItem('isNewUser');
    setTimeout(() => {
      firebase.auth().signOut().then(() => {
        history.push('/cae-login');
      });
    }, 500);
  }

  function handleNavbar() {
    const navbar = document.getElementById('navbar-cae');
    const info = document.getElementById('info-user-navbar');
    const textLink = document.getElementsByClassName('info-text');
    const footer = document.getElementById('footer-navbar');
    const image = document.getElementById('arrow-img');
    const item = document.getElementsByClassName('navbar-item');

    if (navbarIsOpen) {
      info.style = 'opacity: 0; transition: all 100ms ease-in-out;';
      navbar.style = 'width: 70px; justify-content: flex-start;';
      footer.style = 'opacity: 0; transition: all 100ms ease-in-out;';
      image.style = 'transform: rotate(-180deg)';
      for (let i = 0; i < textLink.length; i++) {
        item[i].style = 'justify-content: center';
        textLink[i].style = 'opacity: 0; transition: all 100ms ease-in-out;';
      }
    } else {
      navbar.style = 'width: 267px; justify-content: space-between;';
      info.style = 'opacity: 1; transition: all 1000ms ease-in-out;';
      footer.style = 'opacity: 1; transition: all 1000ms ease-in-out;';
      image.style = 'transform: rotate(0)';
      for (let i = 0; i < textLink.length; i++) {
        item[i].style = 'justify-content: space-evenly;';
        textLink[i].style = 'opacity: 1; transition: all 1000ms ease-in-out;';
      }
    }

    setNavnarIsOpen(!navbarIsOpen);
  }

  async function handleGetUserInfo() {
    const uid = firebase.auth().currentUser.uid;
    const user = await getUser(uid);

    if (user != null) {
      localStorage.setItem('userInfos', JSON.stringify(user));
      localStorage.setItem('uid', uid);
      setUserInfos(user);
      setUid(uid);
    }
    else
      history.push("/cae-login")
  }

  useEffect(() => {
    handleGetUserInfo();
  }, []);

  useEffect(() => {
    if (userInfos)
      localStorage.setItem('userInfos', JSON.stringify(userInfos));
  }, [userInfos]);

  return (
    <div className="Cae">
      <div className="header-container">
        <div className="logo-div-header">
          <img src={LogoBrasilCae} />
          <span>CAÊ</span>
        </div>
        <div className="options-header" onClick={() => openOptionsPopup()}>
          <img src={OptionsIcon} />
          <div className="popup-options-header" id="options-popup">
            { /* <span>Temas</span>
            <div className="hr-div" />
            <div className="theme-options">
              <div className="div-theme" id="active-theme">
                <div className="div-radio-edit theme-options-card">
                  <label className='radio-edit'>
                    <input type='radio' name="theme" id="light-theme" checked={true} />
                    <div className='radio-input radio-theme' />
                  </label>
                  <label className="label-radio-edit label-theme-options" htmlFor="light-theme">Claro</label>
                </div>
              </div>
              <div className="div-theme">
                <div className="div-radio-edit theme-options-card">
                  <label className='radio-edit'>
                    <input type='radio' name="theme" id="dark-theme" checked={false} />
                    <div className='radio-input radio-theme' />
                  </label>
                  <label className="label-radio-edit label-theme-options" htmlFor="dark-theme">Escuro</label>
                </div>
              </div>
            </div> */}
            <span>Conta</span>
            <div className="hr-div" />
            <div className="div-buttons-options-header">
              { /* <div>
                <img src={ConfigIcon} />
                <span>Gerenciar</span>
              </div> */
              }
              <div onClick={() => logout()}>
                <span>Sair</span>
                <img src={LogoutIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="body-container">
        <div className="navbar-cae" id="navbar-cae">
          <div className="user-profile-navbar" onClick={() => history.push('/cae/perfil')}>
            <div className="photo-user-navbar">
              {
                userInfos ? <img src={userInfos.image !== '' ? userInfos.image : UserIcon} /> : <img src={UserIcon} />
              }
            </div>
            <div className="info-user-navbar" id="info-user-navbar">
              <span>{userInfos ? userInfos.name : ""}</span>
              <span>{userInfos ? userInfos.city : ""}</span>
            </div>
            <div className="handler-size-navbar" onClick={() => handleNavbar()}>
              <img src={ArrowIcon} id="arrow-img" />
            </div>
          </div>
          <div className="items-navbar">
            {
              navbarItems.map((item, index) => {
                if (userInfos === null || usersPermissions[userInfos.role].includes(item.title)) {
                  return (
                    <div className="navbar-item" id={window.location.pathname === item.link ? 'active' : ''} key={'Navbar' + item.title + index} onClick={() => history.push(item.link)}>
                      <div className="info-icon">
                        <img src={item.icon} />
                      </div>
                      <div className="info-text">
                        <span>{item.title}</span>
                      </div>
                    </div>
                  );
                }
              })
            }
          </div>
          <div className="footer-navbar" id="footer-navbar">
            <span>Powered by</span>
            <img src={LogoDestinosInteligentes} />
          </div>
        </div>
        <div className="handler-pages">
            {
              uid !== null && userInfos !== null ?
              <MyContext.Provider value={{ userInfos, setUserInfos }}>
                <Switch>
                  <Route path="/cae/experiencias" component={Experiences}/>
                  <Route path="/cae/empresas" component={Agencys}/>
                  <Route path="/cae/perfil" component={ProfileCae}/>
                </Switch> 
                <Footer />
              </MyContext.Provider > : <Footer />
            }
        </div>
      </div>
    </div>
  );
}