import ExperiencesIcon from '../../assets/imgs/NavbarItems/turism_plan_icon.svg';
import AgencysIcon from '../../assets/imgs/NavbarItems/city_icon.svg';

export const navbarItems = [
  {
    title: 'Experiências',
    icon: ExperiencesIcon,
    link: '/cae/experiencias',
  },
  {
    title: 'Empresas',
    icon: AgencysIcon,
    link: '/cae/empresas',
  },
];